import React, { useState, useContext, useEffect } from "react";
import classes from "../MainContent.module.css";
import style from "./StoreLinkSKU.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import comment from "../../../../icons/comment.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";

function StoreLinkSKU() {
  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);

  let [storeLinkData, setStoreLinkData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);
  let [dataToPasson, setDataToPasson] = useState([]);

  let [page, setPage] = useState(1);
  let [dSP, setDSP] = useState(true);
  let [dSN, setDSN] = useState(false);

  let handleAdd = () => {
    setDataToPasson(storeLinkData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  let handleEdit = (data) => {
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
  };

  let handleDelete = (id) => {
    console.log(id);
    fetch(`http://3.7.220.43/api/games/game_agerating/${id}/`, {
      method: "DELETE",
      mode: "cors",
    }).then(() => getData());
  };

  let getData = () => {
    fetch(
      `http://3.7.220.43/api/games/storelinkskuandmsrp-by-game/20645/?&platform_id=${
        platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
      }&distribution_id=${
        distributions !== []
          ? distributions.map((elt) => elt["id"]).join(",")
          : ""
      }&locality_id=${
        regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
      }&edition_id=${
        editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("STORELINK - GET request made", data);
        setStoreLinkData(data);
      });
  };

  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, distributions, editions]);

  useEffect(() => {
    // console.log("storeLinkData.length", storeLinkData.length);
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= storeLinkData.length / 5 && setDSN(true);
    page < storeLinkData.length / 5 && setDSN(false);
  }, [page, storeLinkData]);

  return (
    <div id="storeLinks" className={classes.section}>
      <div>
        {!showAge && (
          <h2
            onClick={() => setShowage(!showAge)}
            className={classes.buttonTextCompressed}
          >
            Store Link, SKU / BC, MSRP
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2
                className={classes.buttonTextExpanded}
                onClick={() => setShowage(!showAge)}
              >
                Store Link, SKU / BC, MSRP
              </h2>
              <span style={{ marginLeft: "20px" }}>
                <img
                  src={comment}
                  alt="commentBtn"
                  onClick={() => setShowComments(!showComments)}
                />
              </span>
              {showComments && (
                <Comments
                  setShowComments={setShowComments}
                  showComments={showComments}
                />
              )}
            </div>
            <div className={style.gridContainer}>
              <div>
                <div>
                  <div className={style.SectionMid}>
                    <div className={style.sectionHeading}>Summary</div>
                    <div>
                      <button
                        className={style.AddBtn}
                        onClick={() => handleAdd()}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className={style.tableContainer}>
                    <table>
                      <thead>
                        <tr>
                          <th className={style.tableHeading}>Platform</th>
                          <th className={style.tableHeading}>Edition</th>
                          <th className={style.tableHeading}>Link</th>
                          <th className={style.tableHeading}>SKU</th>
                          <th className={style.tableHeading}>Barcode</th>
                          <th className={style.tableHeading}>MSRP</th>
                          <th className={style.tableHeading}>Distribution</th>
                          <th className={style.tableHeading}>Region</th>
                          <th className={style.tableHeading}>Retailer SKU</th>
                        </tr>
                      </thead>
                      <tbody>
                        {storeLinkData.map((el) => {
                          return (
                            <tr key={nanoid()}>
                              <td>
                                {el["platform_detail"] === null
                                  ? "null"
                                  : el["platform_detail"]["name"]}
                              </td>
                              <td>
                                {el["edition_detail"] === null
                                  ? "null"
                                  : el["edition_detail"]["name"]}
                              </td>
                              <td>
                                <a
                                  href={
                                    el["urls"]
                                      ? el["urls"]
                                      : "https://example.com"
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {el["urls"] === null ? "null" : el["urls"]}
                                </a>
                              </td>
                              <td>{el["sku"]}</td>
                              <td>{el["barcode"]}</td>
                              <td>{el["msrp"]}</td>
                              <td>{el["distribution_detail"]["name"]}</td>
                              <td>{el["region_detail"]["name"]}</td>
                              <td>{el["sku"]}</td>
                              <td className={style.SectionBtns}>
                                <button onClick={() => handleEdit(el)}>
                                  <img src={edit} alt="Edit Button" />
                                </button>
                              </td>
                              <td className={style.SectionBtns}>
                                <button
                                  onClick={() => {
                                    handleDelete(el.id);
                                  }}
                                >
                                  <img src={d_btn} alt="Delete Button" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <button disabled={dSP} onClick={() => setPage(page - 1)}>
                    Prev
                  </button>
                  <button>{page}</button>
                  <button disabled={dSN} onClick={() => setPage(page + 1)}>
                    Next
                  </button>
                </div>
              </div>
              <div>
                {showEdit && (
                  <Edit setShowEdit={setShowEdit} dataPassed={dataToPasson} />
                )}
                {showAdd && (
                  <Add
                    setShowAdd={setShowAdd}
                    storeLink={true}
                    dataPassed={dataToPasson}
                    getData={getData}
                  />
                )}
              </div>
            </div>
            <CollapseBtn setState={setShowage} state={showAge} />
          </div>
        )}
      </div>
    </div>
  );
}

export default StoreLinkSKU;
