import React, { useState, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function EditLink({ refresh, dataToPasson }) {
  let init = {
    // platforms: [],
    // region: [],
    link_type: "",
    url: "",
    // edition: "",
    // game: 4,
  };

  let [formData, setFormData] = useState(init);
  let { platforms, regions, editions } = useContext(AuthContext);

  let [selplatforms, setselPlatforms] = useState(formData.platforms);
  let [selRegions, setselRegions] = useState(formData.region);
  let [selEdition, setselEdition] = useState(formData.edition);
  let [selLinkType, setselLinkType] = useState(formData.link_type);

  let linkType = [
    {
      id: 1,
      name: "Official",
      label: "Official",
      value: "Official",
    },
    {
      id: 2,
      name: "Twitter",
      label: "Twitter",
      value: "Twitter",
    },
  ];

  let handleFormData = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  let handleSave = () => {
    setFormData(init);
    // setselPlatforms([]);
    // setselEdition([]);
    // setselRegions([]);
    setselLinkType([]);
    refresh();
  };
  useEffect(() => {
    console.log("Somethings being changed");
    console.log("sellinktypes", selLinkType);
    setFormData({
      ...formData,
      // platforms: selplatforms.map((el) => el.id),
      // region: selRegions.map((el) => el.id),
      // region: selRegions.id,
      // edition: selEdition.id,
      link_type: selLinkType.label,
    });
  }, [selplatforms, selEdition, selRegions, selLinkType]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      {/* <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        />
      </div> */}
      <div></div>
      {/* <div style={{ textAlign: "center" }}>Region</div>
      <div>
        <MultiSelect
          options={regions}
          value={selRegions}
          onChange={setselRegions}
          labelledBy="Select Regions"
        />
        <Select options={regions} value={selRegions} onChange={setselRegions} />
      </div> */}
      <div></div>
      {/* <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <Select
          options={editions}
          value={selEdition}
          onChange={setselEdition}
        />
      </div> */}
      <div></div>
      <div style={{ textAlign: "center" }}>Link</div>
      <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
        <Select
          options={linkType}
          value={selLinkType}
          onChange={setselLinkType}
        />
        <input
          id="url"
          onChange={handleFormData}
          type="text"
          value={formData.url}
          placeholder="Link here"
        />
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log(formData);
            // try {
            //   fetch(
            //     `http://3.7.220.43/api/games/link/${dataToPasson.reference_id}/`,
            //     {
            //       method: "PATCH",
            //       body: JSON.stringify(formData),
            //       headers: {
            //         "Access-Control-Allow-Origin": "*",
            //         "Content-Type": "application/json",
            //       },
            //     }
            //   )
            //     .then((res) => res.json())
            //     .then(({ type }) => {
            //       console.log("Response is:,", type);
            //       handleSave();
            //     });
            // } catch (error) {
            //   alert(error[0]);
            //   console.log("error is:", error[0]);
            // }
            fetch(
              `http://3.7.220.43/api/games/link/${dataToPasson.reference_id}/`,
              {
                method: "PATCH",
                body: JSON.stringify(formData),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(() => {
                handleSave();
              });
          }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default EditLink;
