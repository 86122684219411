import styles from "./header.module.css";
import Navigate from "./navigate/Navigate";
import Filter from "./filters/Filters";
import Search from "./search/search";
import GameName from "./gameName/gameName";
import { useParams } from "react-router";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/DataContext";

function Header() {
  let { setGameid } = useContext(AuthContext);
  let { id: gameid } = useParams();
  useEffect(() => {
    setGameid(gameid);
  }, [gameid]);

  return (
    <div>
      {console.log("SSS", gameid)}
      <div className={styles.header_container}>
        <div className={styles.header_child}>
          <GameName />
          <Filter />
          <Navigate />
          <Search />
        </div>
      </div>
      <div className={styles.header_body}></div>
    </div>
  );
}

export default Header;
