import React from "react";
import "./addedit.module.css";

function Comments({ setShowComments, showComments }) {
  return (
    <span
      style={{
        backgroundColor: "yellow",
        height: "fit-content",
        padding: "10px",
        margin: "0 10px",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <span>Comments</span>
      {/* <input type="text" name="comments" id="commentBox" /> */}
      <textarea name="comments" id="" cols="50" rows="1"></textarea>
      <button onClick={() => setShowComments(!showComments)}>x</button>
    </span>
  );
}

export default Comments;
