import React, { useState } from "react";
// import { AuthContext } from "../../../../../context/DataContext";

function EditDescription({ refresh, dataToPasson }) {
  let [formData, setFormData] = useState({});
  const [file, setFile] = useState("");
  // let { platforms, regions, editions, titles } = useContext(AuthContext);

  let handleFormData = ({ target }) => {
    const { name, value } = target;
    if (file) {
      const data = new FormData();

      data.append("file", file);
      data.append("name", file.name);
      formData.updatedFile = data;
    }
    setFormData({ ...formData, [name]: value });
  };
  let handleSave = () => {
    setFormData({});

    refresh();
  };

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
        <div>Description</div>
        <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}></div>
        <div></div>
        <div></div>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div>
            <div>File URL</div>
            <div>
              <input
                type="text"
                onChange={(e) => handleFormData(e)}
                name="decription"
              />
            </div>
            <div></div>
            <div>Upload file</div>
            <div>
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                name="key_features"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log("formDataDescription:", formData);
            fetch(
              `http://3.7.220.43/api/games/description-and-keyfeature/${dataToPasson.reference_id}/`,
              {
                method: "PATCH",
                body: JSON.stringify(formData),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(() => {
                handleSave();
              });
          }}
        >
          SAVE
        </button>
      </div>
    </>
  );
}

export default EditDescription;
