import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function EditPackagecontent({ refresh, dataToPasson }) {
  console.log("refresh:", refresh);
  // console.log("dataToPasson:", dataToPasson);

  let init = {
    // platforms: [],
    // region: [],
    // title_type: "",
    // title: "",
    // edition: "",
    package_content: [],
  };

  let [formData, setFormData] = useState(init);
  let { platforms, regions, editions, packageContents } =
    useContext(AuthContext);
  console.log("pppppp", packageContents);
  let [selpackageContent, setselPackageContent] = useState(
    formData.package_content
  );

  console.log("packageContentsDROPDOWN:", packageContents);

  // let handleFormData = ({ target }) => {
  //   packageContents;
  //   const { name, value } = target;
  //   setFormData({ ...formData, [name]: value });
  // };
  let handleSave = () => {
    setFormData(init);
    setselPackageContent([]);

    refresh();
  };
  useEffect(() => {
    console.log("Somethings being changed");
    setFormData({
      ...formData,
      // platforms: selplatforms.map((el) => el.id),
      package_content: selpackageContent.map((el) => el.id),
    });
  }, [selpackageContent]);

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
        <div>Description</div>
        <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}></div>
        <div></div>
        <div></div>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div>
            <div>Retailer SKU</div>
            <div>
              {/* <select
                onChange={(e) => handleFormData(e)}
                name="package_content"
                id="pkgContentdropdown"
              >
                <option value="sample1">sample1</option>
                <option value="sample2">sample2</option>
                <option value="sample3">sample3</option>
              </select> */}
              {/* <Select
                options={titleTypes}
                value={selTitleType}
                onChange={setselTitleType}
              /> */}
            </div>
            <div></div>
            <div>
              <Select
                options={packageContents}
                value={selpackageContent}
                onChange={setselPackageContent}
                labelledBy="Select Platforms"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log("formDataDescription:", formData);
            fetch(
              `http://3.7.220.43/api/games/package-content-type/75455_2_0_10/`,
              {
                method: "PATCH",
                body: JSON.stringify(formData),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(() => {
                handleSave();
              });
          }}
        >
          SAVE
        </button>
      </div>
    </>
  );
}

export default EditPackagecontent;
