import Header from "./components/header/header";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import UIpage from "../src/pages/UIpage";
// import { useContext } from "react";
// import AuthState from "./context/authState";
import { Link, Route, Router, Routes } from "react-router-dom";
function App() {
  // let contextValue = useContext(AuthState);

  // function onRenderCallback(
  //   id, // the "id" prop of the Profiler tree that has just committed
  //   phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  //   actualDuration, // time spent rendering the committed update
  //   baseDuration, // estimated time to render the entire subtree without memoization
  //   startTime, // when React began rendering this update
  //   commitTime, // when React committed this update
  //   interactions // the Set of interactions belonging to this update
  // ) {
  //   // Aggregate or log render timings...
  //   console.log(
  //     // "The time taken for rendering",
  //     // id,
  //     // "is",
  //     // actualDuration,
  //     // "ms",
  //     // baseDuration,
  //     // "with start time at",
  //     // startTime,
  //     // "ending at",
  //     // commitTime,
  //     // "&",
  //     // interactions,
  //     // "interactions",
  //     "PERFORMANCE",
  //     {
  //       id,
  //       phase,
  //       actualDuration,
  //       baseDuration,
  //       startTime,
  //       commitTime,
  //       interactions,
  //     }
  //   );
  // }
  return (
    <div style={{ fontSize: "12px" }}>
      <Routes>
        <Route exact path="/:id" element={<UIpage />}>
          {/* <Header />
      <Body />
      <Footer /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
