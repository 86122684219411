import React, { useState, useEffect, useContext } from "react";
import classes from "../MainContent.module.css";
import style from "./Titles.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import comment from "../../../../icons/comment.svg";
import filter_btn from "../../../../icons/filter.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";
import { useParams } from "react-router";

function Titles() {
  // for (let i = 0; i < filterdrop.length; i++) {
  //   console.log("elem", filterdrop[i]);
  // }

  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);

  // console.log("sdfg", arrDistribution);

  let temp = useParams();
  let Game_id = temp.id;
  console.log("gameid", Game_id);

  let [titleData, setTitleData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);
  let [dataToPasson, setDataToPasson] = useState([]);

  let [page, setPage] = useState(1);
  let [dSP, setDSP] = useState(true);
  let [dSN, setDSN] = useState(false);

  let [filterRegion, setfilterRegion] = useState("");
  let [filterPlatform, setfilterPlatform] = useState("");
  let [filterType, setfilterType] = useState("");
  let [filterTitle, setfilterTitle] = useState("");
  console.log("filtertitle", filterTitle);
  let [filterEdition, setfilterEdition] = useState("");
  let [filterDistribution, setfilterDistribution] = useState("");

  let [filterDropdown, setfilterdropdown] = useState([]);
  // console.log("filterdropdown", filterDropdown);

  const [showRegionfilter, setShowRegionfilter] = useState(false);
  const [showPlatformfilter, setShowPlatformfilter] = useState(false);
  const [showTypefilter, setShowTypefilter] = useState(false);
  const [showTitlefilter, setShowTitlefilter] = useState(false);
  const [showEditionfilter, setShowEditionfilter] = useState(false);
  const [showDistributionfilter, setShowDistributionfilter] = useState(false);

  var arrDistribution = {};
  var arrRegion = {};
  var arrPlatform = {};

  let [loading, setLoading] = useState(true);

  let handleAdd = () => {
    setDataToPasson(titleData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  let handleEdit = (data) => {
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
    let id_to_edit = data.details.map((elem) => elem.title_id).join("_");
    // console.log("id_to_edit", id_to_edit);
  };

  let handleDelete = (id) => {
    let input = prompt("Type 'delete' to delete the entry");
    if (input.toLowerCase() === "delete") {
      // console.log(id);
      // setTimeout(() => {
      // alert("DELETED");
      // }, 500);
      fetch(`http://3.7.220.43/v1/api/delete-titles/${id}`, {
        method: "DELETE",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }).then(() => {
        setTimeout(() => {
          getData();
          alert("DELETED Data!");
        }, 500);
        // alert("DELETED DATA");
        // getData();
      });
    } else {
      setTimeout(() => {
        alert("Didn't perform DELETE");
      }, 500);
      console.log("wrong input");
    }
  };

  // let getData = () => {
  //   setLoading(true);
  //   // fetch("http://3.7.220.43/api/games/titles-by-game/20443/")
  //   fetch(
  //     `http://3.7.220.43/api/games/titles-by-game/20645/?&platform_id=${
  //       platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
  //     }&distribution_id=${
  //       distributions !== []
  //         ? distributions.map((elt) => elt["id"]).join(",")
  //         : ""
  //     }&locality_id=${
  //       regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
  //     }&edition_id=${
  //       editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
  //     }`
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log("GET request made", data);
  //       setLoading(false);
  //       setTitleData(data);
  //     });
  // };

  let getData = () => {
    fetch(
      `http://3.7.220.43/v1/api/titles/${Game_id}?locality_ids=${
        platforms !== [] ? platforms.map((el) => el.release_id).join("%2C") : ""
      }&distribution_ids=&edition_ids=&release_ids=`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("GET request made qwerty", data);
        setTitleData(data);
        // setfilterdropdown(data);
      });
  };
  let platform_dup_remove = {};
  titleData.map((elem) =>
    elem.details.map((el) => {
      if (el.platform_name in platform_dup_remove) {
        return false;
      } else {
        platform_dup_remove[el.platform_name] = true;
      }
    })
  );
  let Region_dup_remove = {};
  titleData.map((elem) =>
    elem.details.map((el) => {
      if (`${el.locality_name} (${el.locality_code})` in Region_dup_remove) {
        return false;
      } else {
        Region_dup_remove[`${el.locality_name} (${el.locality_code})`] = true;
      }
    })
  );
  let Distribution_dup_remove = {};
  titleData.map((elem) =>
    elem.details.map((el) => {
      if (el.distribution_name in Distribution_dup_remove) {
        return false;
      } else {
        Distribution_dup_remove[el.distribution_name] = true;
      }
    })
  );
  let collapse = () => {
    setLoading(!loading);
    setShowage(!showAge);
    setShowAdd(false);
    setShowEdit(false);
    setTitleData([]);
  };
  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, distributions, editions]);

  useEffect(() => {
    return () => {
      // console.log("Title Component closed");
      setShowAdd(false);
      setTitleData([]);
    };
  }, []);

  useEffect(() => {
    // console.log("titleData.length", titleData.length);
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= titleData.length / 5 && setDSN(true);
    page < titleData.length / 5 && setDSN(false);
  }, [page, titleData]);

  return (
    <div id="titles" className={classes.section}>
      {/* {console.log(
        "Plafors filter data",
        platforms,
        regions,
        editions,
        distributions
      )} */}
      <div>
        {!showAge && (
          <h2
            onClick={() => {
              setLoading(!loading);
              setShowage(!showAge);
            }}
            className={classes.buttonTextCompressed}
          >
            Titles
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2
                className={classes.buttonTextExpanded}
                onClick={() => collapse()}
              >
                Titles
              </h2>
              <span
                style={{
                  margin: "10px",
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <img
                  src={comment}
                  alt="commentBtn"
                  onClick={() => setShowComments(!showComments)}
                />
              </span>
              {showComments && (
                <Comments
                  setShowComments={setShowComments}
                  showComments={showComments}
                />
              )}
            </div>
            <div className={style.gridContainer}>
              <div>
                <div>
                  <div className={style.SectionMid}>
                    <h4 className={style.summary}>Summary</h4>
                    {/* <div className={style.sectionHeading}>Summary</div> */}
                    {/* <div>
                      <button
                        className={style.AddBtn}
                        onClick={() => handleAdd()}
                      >
                        ADD
                      </button>
                    </div> */}
                  </div>
                  <div className={style.tableContainer}>
                    {loading && <h2>Loading ...</h2>}
                    {!loading && (
                      <table>
                        <thead>
                          <tr>
                            <th
                              style={{ maxWidth: "55px" }}
                              className={style.tableHeading}
                              // style={{ maxWidth: "55px" }}
                            >
                              {!showRegionfilter ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    onClick={() =>
                                      setShowRegionfilter(!showRegionfilter)
                                    }
                                    className={style.filterImg}
                                    src={filter_btn}
                                    alt="filter_logo"
                                  />
                                  <h5 style={{ display: "inline-block" }}>
                                    Region
                                  </h5>
                                </div>
                              ) : (
                                <div>
                                  <select
                                    className={style.input}
                                    onChange={(e) =>
                                      setfilterRegion(e.target.value)
                                    }
                                  >
                                    <option defaultValue={true} value="">
                                      ALL
                                    </option>
                                    {Object.keys(Region_dup_remove).map(
                                      (elem) => (
                                        <option>{elem}</option>
                                      )
                                    )}
                                  </select>
                                  <span
                                    onClick={() => {
                                      setShowRegionfilter(!showRegionfilter);
                                      setfilterRegion("");
                                    }}
                                  >
                                    &nbsp;X
                                  </span>
                                </div>
                              )}
                            </th>
                            <th
                              style={{
                                maxWidth: "55px",
                                minWidth: "fit-content",
                              }}
                              className={style.tableHeading}
                              // style={{ maxWidth: "55px" }}
                            >
                              {!showPlatformfilter ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    onClick={() => {
                                      setShowPlatformfilter(
                                        !showPlatformfilter
                                      );
                                      setfilterPlatform("");
                                    }}
                                    className={style.filterImg}
                                    src={filter_btn}
                                    alt="filter_logo"
                                  />
                                  <h5 style={{ display: "inline-block" }}>
                                    Platform
                                  </h5>
                                </div>
                              ) : (
                                <div>
                                  <select
                                    className={style.input}
                                    onChange={(e) =>
                                      setfilterPlatform(e.target.value)
                                    }
                                  >
                                    <option defaultValue={true} value="">
                                      ALL
                                    </option>
                                    {Object.keys(platform_dup_remove).map(
                                      (elem) => (
                                        <option>{elem}</option>
                                      )
                                    )}
                                  </select>
                                  <span
                                    onClick={() => {
                                      setShowPlatformfilter(
                                        !showPlatformfilter
                                      );
                                      setfilterPlatform("");
                                    }}
                                  >
                                    &nbsp;X
                                  </span>
                                </div>
                              )}
                            </th>
                            <th
                              style={{ maxWidth: "55px" }}
                              className={style.tableHeading}
                              // style={{ maxWidth: "55px" }}
                            >
                              {!showTypefilter ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    onClick={() =>
                                      setShowTypefilter(!showTypefilter)
                                    }
                                    className={style.filterImg}
                                    src={filter_btn}
                                    alt="filter_logo"
                                  />
                                  <h5 style={{ display: "inline-block" }}>
                                    Type
                                  </h5>
                                </div>
                              ) : (
                                <div>
                                  {/* <input
                                    className={style.input}
                                    placeholder="Type"
                                    onChange={(e) =>
                                      setfilterType(e.target.value)
                                    }
                                  /> */}
                                  <select
                                    className={style.input}
                                    onChange={(e) =>
                                      setfilterType(e.target.value)
                                    }
                                  >
                                    <option defaultValue={true} value="">
                                      ALL
                                    </option>
                                    {titleData.map((elem) => (
                                      <option>{elem.title_type}</option>
                                    ))}
                                  </select>
                                  <span
                                    onClick={() => {
                                      setShowTypefilter(!showTypefilter);
                                      setfilterType("");
                                    }}
                                  >
                                    &nbsp;X
                                  </span>
                                </div>
                              )}
                            </th>

                            <th
                              style={{ maxWidth: "55px" }}
                              className={style.tableHeading}
                              // style={{ maxWidth: "55px" }}
                            >
                              {!showTitlefilter ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    onClick={() =>
                                      setShowTitlefilter(!showTitlefilter)
                                    }
                                    className={style.filterImg}
                                    src={filter_btn}
                                    alt="filter_logo"
                                  />
                                  <h5 style={{ display: "inline-block" }}>
                                    Title
                                  </h5>
                                </div>
                              ) : (
                                <div>
                                  <select
                                    className={style.input}
                                    onChange={(e) =>
                                      setfilterTitle(e.target.value)
                                    }
                                  >
                                    <option defaultValue={true} value="">
                                      ALL
                                    </option>
                                    {titleData.map((elem) => (
                                      <option>{elem.title}</option>
                                    ))}
                                  </select>

                                  <span
                                    onClick={() => {
                                      setShowTitlefilter(!showTitlefilter);
                                      setfilterTitle("");
                                    }}
                                  >
                                    &nbsp;X
                                  </span>
                                </div>
                              )}
                            </th>

                            <th
                              style={{
                                // maxWidth: "55px",
                                minWidth: "70px",
                              }}
                              className={style.tableHeading}
                              // style={{ maxWidth: "55px" }}
                            >
                              {!showEditionfilter ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    onClick={() =>
                                      setShowEditionfilter(!showEditionfilter)
                                    }
                                    className={style.filterImg}
                                    src={filter_btn}
                                    alt="filter_logo"
                                  />
                                  <h5 style={{ display: "inline-block" }}>
                                    Edition
                                  </h5>
                                </div>
                              ) : (
                                <div>
                                  {/* <input
                                    className={style.input}
                                    placeholder="Edition"
                                    onChange={(e) =>
                                      setfilterEdition(e.target.value)
                                    }
                                  /> */}
                                  <select
                                    className={style.input}
                                    onChange={(e) =>
                                      setfilterEdition(e.target.value)
                                    }
                                  >
                                    <option defaultValue={true} value="">
                                      ALL
                                    </option>
                                    {titleData.map((elem) => (
                                      <option>{elem.edition_name}</option>
                                    ))}
                                  </select>
                                  <span
                                    onClick={() => {
                                      setShowEditionfilter(!showEditionfilter);
                                      setfilterEdition("");
                                    }}
                                  >
                                    &nbsp;X
                                  </span>
                                </div>
                              )}
                            </th>
                            <th
                              style={{
                                minWidth: "80px",
                              }}
                              className={style.tableHeading}
                              // style={{ maxWidth: "55px" }}
                            >
                              {!showDistributionfilter ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    onClick={() =>
                                      setShowDistributionfilter(
                                        !showDistributionfilter
                                      )
                                    }
                                    className={style.filterImg}
                                    src={filter_btn}
                                    alt="filter_logo"
                                  />
                                  <h5 style={{ display: "inline-block" }}>
                                    Distribution
                                  </h5>
                                </div>
                              ) : (
                                <div>
                                  <select
                                    className={style.input}
                                    onChange={(e) =>
                                      setfilterDistribution(e.target.value)
                                    }
                                  >
                                    <option defaultValue={true} value="">
                                      ALL
                                    </option>
                                    {Object.keys(Distribution_dup_remove).map(
                                      (elem) => (
                                        <option>{elem}</option>
                                      )
                                    )}
                                  </select>
                                  <span
                                    onClick={() => {
                                      setShowDistributionfilter(
                                        !showDistributionfilter
                                      );
                                      setfilterDistribution("");
                                    }}
                                  >
                                    &nbsp;X
                                  </span>
                                </div>
                              )}
                            </th>
                            <th className={style.tableHeading}>
                              <button
                                className={style.AddBtn}
                                onClick={() => handleAdd()}
                              >
                                Add
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {console.log("filter", filterType)}
                          {titleData
                            ///-------------------------------------table filter logic--------------------------------------///
                            .filter(
                              (item) =>
                                item.title_type.includes(filterType) &&
                                item.title.includes(filterTitle) &&
                                item.edition_name.includes(filterEdition) &&
                                item.details
                                  .map(
                                    (e) =>
                                      `${e.locality_name} (${e.locality_code})`
                                  )
                                  .join("")

                                  .includes(filterRegion) &&
                                item.details
                                  .map((e) => e.platform_name)
                                  .join("")

                                  .includes(filterPlatform) &&
                                item.details
                                  .map((e) => e.distribution_name)
                                  .join("")

                                  .includes(filterDistribution)
                            )
                            .slice((page - 1) * 5, page * 5)

                            .map((el) => {
                              let arrDistribution = {};
                              let arrRegion = {};
                              let arrPlatform = {};
                              return (
                                <tr key={nanoid()}>
                                  {el.details.filter(function (obj) {
                                    if (
                                      `${obj.locality_name} (${obj.locality_code})` in
                                      arrRegion
                                    ) {
                                      return false;
                                    } else {
                                      arrRegion[
                                        `${obj.locality_name} (${obj.locality_code})`
                                      ] = true;
                                    }
                                  })}
                                  <td>{`${Object.keys(arrRegion).map(
                                    (elem) => elem
                                  )}`}</td>
                                  {el.details.filter(function (obj) {
                                    if (obj.platform_name in arrPlatform) {
                                      return false;
                                    } else {
                                      arrPlatform[obj.platform_name] = true;
                                    }
                                  })}
                                  <td>
                                    {Object.keys(arrPlatform)
                                      .map((elem) => elem)
                                      .join(", ")}
                                  </td>
                                  <td>{el.title_type}</td>
                                  <td>{el.title}</td>
                                  <td>{el.edition_name}</td>
                                  {el.details.filter(function (obj) {
                                    if (
                                      obj.distribution_name in arrDistribution
                                    ) {
                                      return false;
                                    } else {
                                      arrDistribution[
                                        obj.distribution_name
                                      ] = true;
                                    }
                                  })}
                                  <td>
                                    {Object.keys(arrDistribution)
                                      .map((elem) => elem)
                                      .join(", ")}
                                  </td>
                                  <td className={style.SectionBtns}>
                                    <img
                                      onClick={() => handleEdit(el)}
                                      src={edit}
                                      alt="Edit div"
                                    />
                                    <img
                                      onClick={() => {
                                        handleDelete(
                                          el.details
                                            .map((elem) => elem.title_id)
                                            .join("_")
                                        );
                                      }}
                                      src={d_btn}
                                      alt="Delete div"
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <button disabled={dSP} onClick={() => setPage(page - 1)}>
                    Prev
                  </button>
                  <button>{page}</button>
                  <button disabled={dSN} onClick={() => setPage(page + 1)}>
                    Next
                  </button>
                </div>
              </div>
              <div></div>
              <div>
                {showEdit && (
                  <Edit
                    setShowEdit={setShowEdit}
                    getData={getData}
                    title={true}
                    dataPassed={dataToPasson}
                  />
                )}
                {showAdd && (
                  <Add
                    setShowAdd={setShowAdd}
                    dataPassed={dataToPasson}
                    title={true}
                    getData={getData}
                  />
                )}
              </div>
            </div>
            <CollapseBtn
              collapseFn={collapse}
              setState={setShowage}
              setLoading={setLoading}
              loading={loading}
              state={showAge}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Titles;
