import React from "react";
import style from "./addedit.module.css";
import EditAgeRating from "./Edit/EditAgeRating";
import EditTitle from "./Edit/EditTitle";
import EditCover from "./Edit/EditCover";
import EditDescription from "./Edit/EditDescription";
import EditDeveloper from "./Edit/EditDeveloper";
import EditLink from "./Edit/EditLink";
import EditPackageContent from "./Edit/EditPackageContent";
import EditReleaseDate from "./Edit/EditReleaseDate";
import EditStoreLink from "./Edit/EditStoreLink";

function Edit({
  setShowEdit,
  getData,
  dataPassed,
  age,
  title,
  cover,
  description,
  developer,
  link,
  packageContent,
  release,
  storeLink,
}) {
  return (
    <div>
      <div
        className={style.SectionMid}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* {console.log("data that is passed is: ", dataPassed)} */}
        <h4 className={style.sectionHeading}>Edit</h4>
        <button
          className={style.sectionClose}
          onClick={() => setShowEdit(false)}
        >
          X
        </button>
      </div>
      <div className={style.EditContainer}>
        {/* HEADER */}

        {/* <div></div> */}
        {/* BODY */}
        {age && <EditAgeRating refresh={getData} />}
        {title && <EditTitle prevdata={dataPassed} refresh={getData} />}
        {cover && <EditCover refresh={getData} />}
        {description && <EditDescription refresh={getData} />}
        {developer && <EditDeveloper refresh={getData} />}
        {link && <EditLink refresh={getData} />}
        {packageContent && <EditPackageContent refresh={getData} />}
        {release && <EditReleaseDate refresh={getData} />}
        {storeLink && <EditStoreLink refresh={getData} />}
        {/* FOOTER */}
        {/* <div className={style.SaveBtn}>
        <button>SAVE</button>
      </div> */}
      </div>
    </div>
  );
}

export default Edit;
