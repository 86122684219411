import React from "react";
import styles from "./Footer.module.css";
import GameAuditData from "./gameAuditData/GameAuditData";

function Footer() {
  return (
    <div id="footer" className={styles.Footer_cntnr}>
      <div className={styles.dummy}></div>
      <GameAuditData className={styles.Footer_child} />
    </div>
  );
}

export default Footer;
