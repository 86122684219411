import React, { useState, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";

function EditAgeRating({ refresh, dataToPasson }) {
  //   let { platforms, editions, ageRatings, regions, descriptors } =
  //     useContext(AuthContext);

  let [selplatforms, setselPlatforms] = useState([]);

  //   let init = {
  //     game: 4,
  //     platforms: selplatforms,
  //     age_rating: 0,
  //     game_descriptor: "",
  //     regions: "",
  //     edition: 0,
  //   };

  //   let [formData, setFormData] = useState(init);

  //   let handleFormData = (e) => {
  //     setFormData({
  //       ...formData,
  //       [e.target.id]:
  //         e.target.id === "regions" || e.target.id === "game_descriptor"
  //           ? [Number(e.target.value)]
  //           : Number(e.target.value),
  //     });
  //   };

  //   let handleSave = () => {
  //     setFormData(init);
  //     setselPlatforms([]);
  //     refresh();
  //   };

  //   useEffect(() => {
  //     setFormData({
  //       ...formData,
  //       platforms: selplatforms.map((el) => {
  //         return el.id;
  //       }),
  //     });
  //     return null;
  //   }, [selplatforms]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        {/* <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        /> */}
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      <div>
        {/* <select
          style={{ width: "100%" }}
          id="regions"
          value={formData.regions}
          onChange={(e) => {
            console.log("Value for", e.target.id, "is:", e.target.value);
            handleFormData(e);
          }}
        >
          <option>Select Region</option>
          {regions.map((el) => (
            <option key={Math.random()} value={el.id}>
              {el.label}
            </option>
          ))}
        </select> */}
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        {/* <select
          style={{ width: "100%" }}
          id="edition"
          value={formData.edition}
          onChange={(e) => {
            console.log("Value for", e.target.id, "is:", e.target.value);
            handleFormData(e);
          }}
        >
          <option>Select Edition</option>
          {editions.map((el) => (
            <option key={Math.random()} value={el.id}>
              {el.label}
            </option>
          ))}
        </select> */}
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Age Rating</div>
      <div>
        {/* <select
          style={{ width: "100%" }}
          id="age_rating"
          value={formData.age_rating}
          onChange={(e) => {
            console.log("Value for", e.target.id, "is:", e.target.value);
            handleFormData(e);
          }}
        >
          <option>Select Age Rating</option>
          {ageRatings.map((el) => (
            <option key={Math.random()} value={el.id}>
              {el.age_rating}
            </option>
          ))}
        </select> */}
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Game Descriptor (APLHA)</div>
      <div>
        {/* <select
          style={{ width: "100%" }}
          id="game_descriptor"
          value={formData.game_descriptor}
          onChange={(e) => {
            console.log(
              "Value for",
              e.target.id,
              "is:",
              typeof Number(e.target.value)
            );
            handleFormData(e);
          }}
        >
          <option>Select Descriptors</option>
          {descriptors.map((el) => (
            <option key={Math.random()} value={el.id}>
              {el.descriptor}
            </option>
          ))}
        </select> */}
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          //   onClick={() => {
          //     console.log(formData);
          //     fetch("http://3.7.220.43/api/games/game_agerating/", {
          //       method: "POST",
          //       body: JSON.stringify(formData),
          //       headers: {
          //         "Access-Control-Allow-Origin": "*",
          //         "Content-Type": "application/json",
          //       },
          //     })
          //       .then((res) => res.json())
          //       .then(() => {
          //         handleSave();
          //       });
          //   }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default EditAgeRating;
