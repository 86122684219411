import React, { useState, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";
import { Memoizdcomp } from "./DistributionreusableAdd";

function AddTitle({ refresh, dataPassed }) {
  let di = {};
  // var arr;
  // const [idds, setidds] = useState([]);
  // const [option, setOption] = useState([]);
  // const [idsssss, setidsssss] = useState([]);
  // let arr = [...option, release_id];

  var attr;
  // var temp;
  let [dict, setdict] = useState({});
  // let [attribute, setattribute] = useState("");
  // let [dictionary, setdictionary] = useState({});
  // const Callback = (attr) => {
  //   return attr;
  // };
  // setattribute(Callback());
  let { platforms, regions, editions, titleTypes, flg } =
    useContext(AuthContext);
  // console.log("");
  let init = {
    title_type: "",
    title: "",
    edition_ids: [],
    locality_ids: [],
    release_ids: [],
  };

  let tempVar = [];

  let [getdistribution, setgetdistribution] = useState([]);

  let [formData, setFormData] = useState(init);

  let [selplatforms, setselPlatforms] = useState([]);

  let [selRegions, setselRegions] = useState([]);
  let [selEdition, setselEdition] = useState([]);
  let [selTitleType, setselTitleType] = useState([]);

  let [distplatObj, setdistplatObj] = useState([]);

  let handleCallback = (childData) => {
    setdistplatObj([...distplatObj, childData]);
  };
  const Pull = (data, releaseId) => {
    // console.log("ghgh", releaseId, data);
    di[releaseId] = 1;
    let arr = [...data, releaseId];
    // console.log("ghgh", arr);

    // setd(arr);
    // if (releaseId === arr[arr.length - 1]) {
    di[releaseId] = arr.slice(0, -1).map((ele) => ele.distribution_id);
    console.log("hgh", di);

    attr = Object.keys(di).map((elem) => {
      return { id: Number(elem), distributions: di[elem] };
    });
    // clearTimeout(timeout);
    // timeout = setTimeout(() => {
    //   console.log("CAME In timeout");
    //   setdict(attr);
    // }, 500);

    // Callback(attr);

    // useEffect(() => {
    //   setattribute(attr);
    // }, [attr]);

    // setattribute(attr);
    // selplatforms.map((el) => {
    //   for (let key in di) {
    //     console.log("diii", key, el.release_id);
    //     if (key == el.release_id) {
    //       attr = di[key];
    //       console.log("attr---->", attr);
    //     }
    //   }
    //   // setFormData(...formData);
    // });
    // } else {
    //   di[releaseId] = false;
    // }
    console.log("Changing!", attr);
  };
  useEffect(() => {
    let id = selplatforms.map((elem) => elem.release_id).join("_");

    if (id) {
      fetch(`http://3.7.220.43/v1/api/distribution/${id}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log("GET request made");
          setgetdistribution(data);
        });
    }
  }, [selplatforms]);
  // selplatforms.map((el) => {
  //   console.log("sel", di);
  //   for (let key in di) {
  //     console.log("key", key, el.release_id);
  //     if (key === el.release_id) {
  //       let attr = di[key];
  //       console.log("hvhvh", attr);
  //     }
  //   }
  // });
  let setForm = () => {
    // console.log("re rendering", attr);
    // setFormData({
    //   ...formData,
    //   release_ids: attr,

    //   locality_ids: selRegions.map((el) => el.locality_id),
    //   edition_ids: selEdition.edition_id,
    //   title_type: selTitleType.label,
    //   platforms: selplatforms.map((el) => el.release_id),
    // });
    console.log("form after setting", formData);
    try {
      fetch("http://3.7.220.43/v1/api/add-titles/", {
        method: "POST",
        body: JSON.stringify([formData]),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(() => {
          console.log("Request sent!");
          handleSave();
        });
    } catch (error) {
      console.log("REQ not sent");
    }
  };
  {
    console.log("rrr2", attr);
  }
  useEffect(() => {
    // console.log("form252525", attr);
    {
      console.log("dict", dict);
    }
    // Pull(data, releaseId);
    // setForm();

    setFormData({
      ...formData,
      release_ids: attr,

      locality_ids: selRegions.map((el) => el.locality_id),
      edition_ids: [selEdition.edition_id],
      title_type: selTitleType.label,
    });
  }, [selplatforms, selEdition, selRegions, selTitleType, flg, formData.title]);
  // useEffect(() => {
  //   setFormData({ ...formData, distributions: dat });
  // }, [d]);

  // useEffect(() => {
  //   !selplatforms && setgetdistribution([]);
  // }, [selplatforms]);

  let handleFormData = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  let handleSave = () => {
    setFormData(init);
    setselPlatforms([]);
    setselEdition([]);
    setselRegions([]);
    setselTitleType([]);
    refresh();
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      {console.log("Flag", flg)}
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      {/* {console.log("jaibalayya", selRegions)} */}
      <div>
        <MultiSelect
          options={regions}
          value={selRegions}
          onChange={setselRegions}
          labelledBy="Select Regions"
        />
        {/* <Select options={regions} value={selRegions} onChange={setselRegions} /> */}
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <Select
          // isDisabled={true}
          options={editions}
          value={selEdition}
          onChange={setselEdition}
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Title</div>
      <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
        <Select
          options={titleTypes}
          value={selTitleType}
          onChange={setselTitleType}
        />
        <input
          value={formData.title}
          id="title"
          onChange={(e) => handleFormData(e)}
          type="text"
          placeholder="Title name"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Distributions</div>
      <div>
        {selplatforms.map((elem) => {
          let opt = getdistribution
            .filter((el) => el.release_id === elem.release_id)[0]
            ?.distributions_details.map((elt) => {
              return {
                label: elt.distribution_name,
                value: elt.distribution_name,
                distribution_id: elt.distribution_id,
              };
            });
          return (
            <Memoizdcomp
              elem={elem}
              opt={opt}
              selplatforms={selplatforms}
              dataPassed={dataPassed}
              distplatObj={distplatObj}
              setdistplatObj={handleCallback}
              tempVar={tempVar}
              func={Pull}
            />
            // <>
            //   <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
            //     <p>{elem.label}</p>
            //     {selplatforms !== [] && (
            //       <MultiSelect
            //         options={opt || { label: "dummy", value: "dummy" }}
            //         value={option}
            //         onChange={setOption}
            //         style={{ width: "200px" }}
            //       />
            //     )}
            //   </div>
            // </>
          );
        })}
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log("form2525", formData);
            setForm();
            // fetch("http://3.7.220.43/api/games/alternate-titles/", {
            //   method: "POST",
            //   body: JSON.stringify(formData),
            //   headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     "Content-Type": "application/json",
            //   },
            // })
            //   .then((res) => res.json())
            //   .then(() => {
            //     handleSave();
            //   });
            // try {
            //   fetch("http://3.7.220.43/api/games/alternate-titles/", {
            //     method: "POST",
            //     body: JSON.stringify(formData),
            //     headers: {
            //       "Access-Control-Allow-Origin": "*",
            //       "Content-Type": "application/json",
            //     },
            //   })
            //     .then((res) => res.json())
            //     .then(() => {
            //       handleSave();
            //     });
            // } catch (error) {
            //   alert(error[0]);
            //   console.log("error is:", error[0]);
            // }
          }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default AddTitle;
