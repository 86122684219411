import React from "react";

import MainContent from "./mainContent/MainContent";
import Platform from "./platforms/Platform";
import PubsComponent from "./pubsErrors/PubsComponent";

function Body() {
  return (
    <div>
      <Platform />
      <PubsComponent />
      <MainContent />
    </div>
  );
}

export default Body;
