import React, { useState, useEffect } from "react";
import styles from "./gameName.module.css";
import { useParams } from "react-router";

function GameName({ gameName }) {
  let temp = useParams();
  let game_id = temp.id;
  let [newData, setNewData] = useState([]);
  console.log("newdata", newData);

  useEffect(() => {
    // fetch("http://3.7.220.43/api/games/game-detail/20645-a-bird-story/")
    fetch(`http://3.7.220.43/v1/game/${game_id}`)
      .then((res) => res.json())
      .then((data) => {
        setNewData(data[0]);
        console.log("Obt DATA", data);
      });
  }, []);
  return (
    <div className={styles.GameName}>
      <span style={{ fontSize: "20px", fontWeight: "700" }}>
        {newData?.title}
      </span>
    </div>
  );
}

export default GameName;
