import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function EditStoreLink({ refresh, dataToPasson }) {
  let init = {};
  let [formData, setFormData] = useState(init);
  // let { platforms, regions, editions, titles } = useContext(AuthContext);
  // let [selplatforms, setselPlatforms] = useState(formData.platforms);
  // let [selRegions, setselRegions] = useState(formData.region);
  // let [selEdition, setselEdition] = useState(formData.edition);
  let [selTitleType, setselTitleType] = useState(formData.title_type);

  // let titleType = [
  //   {
  //     key: "Official",
  //     value: "Official",
  //     label: "Official",
  //   },
  //   {
  //     key: "Abbreviation",
  //     value: "Abbreviation",
  //     label: "Abbreviation",
  //   },
  //   {
  //     key: "Alt Sub-Title",
  //     value: "Alt Sub-Title",
  //     label: "Alt Sub-Title",
  //   },
  // ];

  let handleFormData = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };
  let handleSave = () => {
    setFormData(init);
    // setselPlatforms([]);
    // setselEdition([]);
    // setselRegions([]);
    setselTitleType([]);
    refresh();
  };
  // useEffect(() => {
  //   console.log("Somethings being changed");
  //   setFormData({
  //     ...formData,
  //     // platforms: selplatforms.map((el) => el.id),
  //     // region: selRegions.map((el) => el.id),
  //     // region: selRegions.id,
  //     // edition: selEdition.id,
  //     // title_type: selTitleType?.label,
  //   });
  // }, [selplatforms, selEdition, selRegions, selTitleType]);
  // console.log("formData:", formData);

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
        <div>Title</div>
        <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}></div>
        <div></div>
        <div></div>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div>
            {/* <div></div> */}
            {/* <div>SKU</div>
            <div>
              <input
                name="sku"
                onChange={(e) => handleFormData(e)}
                type={"text"}
              />
            </div> */}
            <div></div>
            {/* <div>Retailer SKU</div>
             <div>
              <input
                name="retailer_sku"
                onChange={(e) => handleFormData(e)}
                type={"text"}
              />
            </div> */}
            <div></div>
            <div>Barcode</div>
            <div>
              <input
                name="barcode"
                onChange={(e) => handleFormData(e)}
                type={"text"}
              />
            </div>
            <div></div>
            {/* <div>MSRP</div>
             <div>
              <input
                name="msrp"
                onChange={(e) => handleFormData(e)}
                type={"text"}
              />
            </div> */}
            <div></div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log(
              "formDataSTORELINK:",
              dataToPasson.reference_id,
              formData
            );
            fetch(
              `http://3.7.220.43/api/games/barcode-publication/${dataToPasson.reference_id}/`,
              {
                method: "PATCH",
                body: JSON.stringify(formData),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(() => {
                handleSave();
              });
          }}
        >
          SAVE
        </button>
      </div>
    </>
  );
}

export default EditStoreLink;
