import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function AddStoreLink({ refresh }) {
  let { platforms, regions, editions, distributions } = useContext(AuthContext);
  let [selplatforms, setselPlatforms] = useState([]);
  let [selDistributions, setselDistributions] = useState([]);
  let [selRegions, setselRegions] = useState([]);
  let [selEditions, setselEditions] = useState([]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      <div>
        <Select options={regions} value={selRegions} onChange={setselRegions} />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <Select
          options={editions}
          value={selEditions}
          onChange={setselEditions}
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Distribution</div>
      <div>
        <Select
          options={distributions}
          value={selDistributions}
          onChange={setselDistributions}
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Store Link</div>
      <div>
        <input id="" type={"text"} />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>SKU</div>
      <div>
        <input type={"text"} />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Retailer SKU</div>
      <div>
        <input type={"text"} />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Barcode</div>
      <div>
        <input type={"text"} />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>MSRP</div>
      <div>
        <input type={"text"} />
      </div>
      <div></div>
    </div>
  );
}

export default AddStoreLink;
