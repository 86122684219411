import React from "react";
import style from "./addedit.module.css";
import AddAgeRating from "./Add/AddAgeRating";
import AddTitle from "./Add/AddTitle";
import AddCover from "./Add/AddCover";
import AddDescription from "./Add/AddDescription";
import AddDeveloper from "./Add/AddDeveloper";
import AddLink from "./Add/AddLink";
import AddPackageContent from "./Add/AddPackageContent";
import AddReleaseDate from "./Add/AddReleaseDate";
import AddStoreLink from "./Add/AddStoreLink";

function Add({
  setShowAdd,
  getData,
  age,
  title,
  cover,
  description,
  developer,
  link,
  packageContent,
  release,
  storeLink,
}) {
  return (
    <div>
      <div
        className={style.SectionMid}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* {console.log("data that is passed is: ", dataPassed)} */}
        <h4 className={style.sectionHeading}>Add</h4>
        <button
          className={style.sectionClose}
          onClick={() => setShowAdd(false)}
        >
          X
        </button>
      </div>
      <div className={style.EditContainer}>
        {/* HEADER */}

        {/* <div></div> */}
        {/* BODY */}
        {age && <AddAgeRating refresh={getData} />}
        {title && <AddTitle refresh={getData} />}
        {cover && <AddCover refresh={getData} />}
        {description && <AddDescription refresh={getData} />}
        {developer && <AddDeveloper refresh={getData} />}
        {link && <AddLink refresh={getData} />}
        {packageContent && <AddPackageContent refresh={getData} />}
        {release && <AddReleaseDate refresh={getData} />}
        {storeLink && <AddStoreLink refresh={getData} />}
        {/* FOOTER */}
        {/* <div className={style.SaveBtn}>
        <button>SAVE</button>
      </div> */}
      </div>
    </div>
  );
}

export default Add;
