import React, { useState, useEffect, useContext } from "react";
import classes from "../MainContent.module.css";
import style from "./Links.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import close from "../../../../icons/close.svg";
import comment from "../../../../icons/comment.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";

function Links() {
  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);

  let [linksData, setLinksData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);
  let [dataToPasson, setDataToPasson] = useState([]);
  //-------------Logic for Pagination-------------------//

  const [page, setPage] = useState(1);
  const [dSP, setDSP] = useState(true);
  const [dSN, setDSN] = useState(true);

  useEffect(() => {
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= linksData.length / 5 && setDSN(true);
    page < linksData.length / 5 && setDSN(false);
  }, [page, linksData]);

  //-------------filter states here---------------------//

  const [filterRegion, setfilterRegion] = useState("");
  const [filterPlatform, setfilterPlatform] = useState("");
  const [filterType, setfilterType] = useState("");
  const [filterLink, setfilterLink] = useState("");
  const [filterEdition, setfilterEdition] = useState("");
  const [showfilterRegion, setShowfilterRegion] = useState("");
  const [showfilterPlatform, setShowfilterPlatform] = useState("");
  const [showfilterType, setShowfilterType] = useState("");
  const [showfilterLink, setShowfilterLink] = useState("");
  const [showfilterEdition, setShowfilterEdition] = useState("");

  let handleAdd = () => {
    setDataToPasson(linksData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  let handleEdit = (data) => {
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
  };

  let handleDelete = (id) => {
    let input = prompt("Type 'delete' to delete the entry");
    if (input === "delete") {
      console.log(id);
      fetch(`http://3.7.220.43/api/games/link/${id}/`, {
        method: "DELETE",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }).then(() => getData());
    } else {
      console.log("wrong input");
    }
  };

  let getData = () => {
    fetch(
      `http://3.7.220.43/api/games/links-by-game/20645/?&platform_id=${
        platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
      }&distribution_id=${
        distributions !== []
          ? distributions.map((elt) => elt["id"]).join(",")
          : ""
      }&locality_id=${
        regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
      }&edition_id=${
        editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("GET request made");
        setLinksData(data);
      });
  };

  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, distributions, editions]);

  useEffect(() => {
    // console.log("linksData.length", linksData.length);
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= linksData.length / 5 && setDSN(true);
    page < linksData.length / 5 && setDSN(false);
  }, [page, linksData]);

  return (
    <div id="links" className={classes.section}>
      <div>
        {!showAge && (
          <h2
            onClick={() => setShowage(!showAge)}
            className={classes.buttonTextCompressed}
          >
            Links
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "98% 2%",
                // justifyContent: "space-between",
              }}
            >
              <div>
                <h2
                  className={classes.buttonTextExpanded}
                  onClick={() => setShowage(!showAge)}
                >
                  Links
                </h2>
                <span
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignContent: "center",
                  }}
                >
                  <img
                    src={comment}
                    alt="commentBtn"
                    onClick={() => setShowComments(!showComments)}
                  />
                </span>
                {showComments && (
                  <Comments
                    setShowComments={setShowComments}
                    showComments={showComments}
                  />
                )}
              </div>

              <CollapseBtn setState={setShowage} state={showAge} />
            </div>
            <div className={style.gridContainer}>
              <div>
                <div>
                  <div className={style.SectionMid}>
                    <h4 className={style.summary}>Summary</h4>
                    {/* <div className={style.sectionHeading}>Summary</div> */}
                    {/* <div>
                      <button
                        className={style.AddBtn}
                        onClick={() => handleAdd()}
                      >
                        ADD
                      </button>
                    </div> */}
                  </div>
                  <div className={style.tableContainer}>
                    <input
                      placeholder="region"
                      onChange={(e) => setfilterRegion(e.target.value)}
                    ></input>
                    <input
                      placeholder="platform"
                      onChange={(e) => setfilterPlatform(e.target.value)}
                    ></input>
                    <input
                      placeholder="type"
                      onChange={(e) => setfilterType(e.target.value)}
                    ></input>
                    <input
                      placeholder="link"
                      onChange={(e) => setfilterLink(e.target.value)}
                    ></input>
                    <input
                      placeholder="edition"
                      onChange={(e) => setfilterEdition(e.target.value)}
                    ></input>
                    <table>
                      <thead>
                        <tr>
                          <th className={style.tableHeading}>
                            <h5>Region</h5>
                          </th>
                          <th className={style.tableHeading}>
                            <h5>Platform</h5>
                          </th>
                          <th className={style.tableHeading}>
                            <h5>Type</h5>
                          </th>
                          <th className={style.tableHeading}>
                            <h5>Link</h5>
                          </th>
                          <th className={style.tableHeading}>
                            <h5>Edition</h5>
                          </th>
                          <th className={style.tableHeading}>
                            <button
                              className={style.AddBtn}
                              onClick={() => handleAdd()}
                            >
                              Add
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {linksData
                          .filter(
                            (item) =>
                              `${item["region_detail"]["name"]}(${item["region_detail"]["iso_code"]})`
                                .toLowerCase()
                                .includes(filterRegion) &&
                              item.platforms_detail_list
                                .map((el) => el.name)
                                .join("")
                                .toLowerCase()
                                .includes(filterPlatform) &&
                              item.link_type
                                .toLowerCase()
                                .includes(filterType) &&
                              item.url.toLowerCase().includes(filterLink)
                            //&&
                            // item.edition_detail
                            //   ? item.edition_detail
                            //       .toLowerCase()
                            //       .includes(filterEdition)
                            //   : "primary".toLowerCase().includes(filterEdition)
                          )
                          // .slice((page - 1) * 5, page * 5)
                          .map((el) => {
                            return (
                              <tr key={nanoid()}>
                                <td>{`${el["region_detail"]["name"]} (${el["region_detail"]["iso_code"]})`}</td>
                                <td>
                                  {el["platforms_detail_list"].length !== 0
                                    ? el["platforms_detail_list"]
                                        .map((elt) => elt["name"])
                                        .join(", ")
                                    : "-"}
                                </td>
                                <td>{el["link_type"]}</td>
                                <td>
                                  <a
                                    href={el["url"]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {el["url"]}
                                  </a>
                                </td>
                                <td>
                                  {el["edition_detail"]
                                    ? el["edition_detail"]
                                    : "Primary"}
                                </td>
                                <td className={style.SectionBtns}>
                                  <button onClick={() => handleEdit(el)}>
                                    <img src={edit} alt="Edit Button" />
                                  </button>
                                </td>
                                <td className={style.SectionBtns}>
                                  <button
                                    onClick={() => {
                                      handleDelete(el.id);
                                    }}
                                  >
                                    <img src={d_btn} alt="Delete Button" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <button disabled={dSP} onClick={() => setPage(page - 1)}>
                      Prev
                    </button>
                    <button>{page}</button>
                    <button disabled={dSN} onClick={() => setPage(page + 1)}>
                      Next
                    </button>
                  </div>
                  <button disabled={dSP} onClick={() => setPage(page - 1)}>
                    Prev
                  </button>
                  <button>{page}</button>
                  <button disabled={dSN} onClick={() => setPage(page + 1)}>
                    Next
                  </button>
                </div>
              </div>
              <div>
                {showEdit && (
                  <Edit
                    setShowEdit={setShowEdit}
                    dataPassed={dataToPasson}
                    link={true}
                    getData={getData}
                  />
                )}
                {showAdd && (
                  <Add
                    setShowAdd={setShowAdd}
                    link={true}
                    dataPassed={dataToPasson}
                    getData={getData}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Links;
