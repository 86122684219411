import React, { useState, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";
import { useParams } from "react-router";
import { Memoizdcomp } from "./Distributionreusable";

function EditTitle({ refresh, prevdata }) {
  let { id: gameid } = useParams();
  let di = {};
  let [dict, setdict] = useState({});
  let { platforms, regions, editions, titleTypes, flg } =
    useContext(AuthContext);

  let init = {
    title_type: "",
    title: prevdata.title,
    edition_ids: [],
    locality_ids: [],
    release_ids: [],
    platforms: [],
  };

  let tempVar = [];
  var attr;

  let [getdistribution, setgetdistribution] = useState([]);

  let [formData, setFormData] = useState(init);

  let removedup_plat = {};
  prevdata.details.map(function (obj) {
    if (obj.platform_name in removedup_plat) {
      return false;
    } else {
      removedup_plat[obj.platform_name] = obj.release_id;
      // console.log("jaibalayya", removedup_plat);
    }
  });
  let [selplatforms, setselPlatforms] = useState(
    Object.keys(removedup_plat).map((el) => {
      return {
        platform_name: el,
        release_id: removedup_plat[el],
        label: el,
        value: el,
      };
    })
  );
  let remove_dup_regions = {};
  prevdata.details.map(function (obj) {
    if (`${obj.locality_name} (${obj.locality_code})` in remove_dup_regions) {
      return false;
    } else {
      remove_dup_regions[`${obj.locality_name} (${obj.locality_code})`] =
        obj.locality_id;
    }
  });
  let [selRegions, setselRegions] = useState(
    Object.keys(remove_dup_regions).map((el) => {
      return {
        locality_name: el,
        locality_id: remove_dup_regions[el],
        label: el,
        value: el,
      };
    })
  );
  let [selEdition, setselEdition] = useState({
    edition_name: prevdata.edition_name,
    label: prevdata.edition_name,
    value: prevdata.edition_name,
    edition_id: prevdata.edition_id,
  });
  let [selTitleType, setselTitleType] = useState({
    title_type: prevdata.title_type,
    label: prevdata.title_type,
    value: prevdata.title_type,
  });

  let [distplatObj, setdistplatObj] = useState([]);

  let handleCallback = (childData) => {
    setdistplatObj([...distplatObj, childData]);
  };

  const Pull = (data, releaseId) => {
    // console.log("ghgh", releaseId, data);
    di[releaseId] = 1;
    let arr = [...data, releaseId];
    // console.log("ghgh", arr);

    // setd(arr);
    // if (releaseId === arr[arr.length - 1]) {
    di[releaseId] = arr.slice(0, -1).map((ele) => ele.distribution_id);
    // console.log("hgh", di);

    attr = Object.keys(di).map((elem) => {
      return { id: Number(elem), distributions: di[elem] };
    });
    // clearTimeout(timeout);
    // timeout = setTimeout(() => {
    //   console.log("CAME In timeout");
    //   setdict(attr);
    // }, 500);

    // Callback(attr);

    // useEffect(() => {
    //   setattribute(attr);
    // }, [attr]);

    // setattribute(attr);
    // selplatforms.map((el) => {
    //   for (let key in di) {
    //     console.log("diii", key, el.release_id);
    //     if (key == el.release_id) {
    //       attr = di[key];
    //       console.log("attr---->", attr);
    //     }
    //   }
    //   // setFormData(...formData);
    // });
    // } else {
    //   di[releaseId] = false;
    // }
    console.log("Changing!", attr);
  };

  useEffect(() => {
    // console.log("selplatforms-->", selplatforms);
  }, [selplatforms]);

  useEffect(() => {
    let id = selplatforms.map((elem) => elem.release_id).join("_");
    if (id) {
      fetch(`http://3.7.220.43/v1/api/distribution/${id}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log("GET request made");
          setgetdistribution(data);
        });
    }
  }, [selplatforms]);
  useEffect(() => {
    // console.log("form252525", attr);
    {
      console.log("dict", dict);
    }
    // Pull(data, releaseId);
    // setForm();

    setFormData({
      ...formData,
      release_ids: attr,
      platforms: selplatforms.map((el) => el.release_id),
      locality_ids: selRegions.map((el) => el.locality_id),
      edition_ids: [selEdition.edition_id],
      title_type: selTitleType.label,
    });
  }, [selplatforms, selEdition, selRegions, selTitleType, flg, formData.title]);
  // console.log("prevdata", prevdata);
  let getDistributions = (e) => {
    // setselRegions;
    // console.log("getdistributiondata", e);
  };

  // useEffect(() => {
  //   !selplatforms && setgetdistribution([]);
  // }, [selplatforms]);

  // let titleType = [
  //   {
  //     id: 1,
  //     name: "Informal",
  //     label: "Informal",
  //     value: "Informal",
  //   },
  //   {
  //     id: 2,
  //     name: "Abbrevation",
  //     label: "Abbrevation",
  //     value: "Abbrevation",
  //   },
  // ];

  let handleFormData = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  let handleSave = () => {
    setFormData(init);
    setselPlatforms([]);
    setselEdition([]);
    setselRegions([]);
    setselTitleType([]);
    refresh();
  };

  // console.log("prev", prevdata);
  let setForm = (data) => {
    console.log("form after setting", data);
    try {
      fetch(`http://3.7.220.43/v1/api/update-titles/${gameid}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("Request SENT!", data);
          handleSave();
        })
        .catch(() => console.log("REQUEST NOT SENT Within CAtch"));
    } catch (error) {
      console.log("REQUEST not sent!", error);
    }
  };

  const editTitleHandler = () => {
    console.log("Testing entry");
    const oldPlatformDetails = prevdata.details;

    const oldPlatformDetailsIds = [];
    const oldRegionDetailsIds = [];
    const oldformatDistributions = {};

    const newPlatformDetailsIds = formData.platforms;
    const newRegionDetailsIds = formData.locality_ids;
    const newformatDistributions = {};
    let newEditionId = 0;

    let finalPlatform = { is_added: [], is_deleted: [] };
    let finalRegion = { is_added: [], is_deleted: [] };
    let finalEdition = false;
    let finalTitle = false;
    let finalTitleType = false;
    let finalDistributions = {};
    // formData.release_ids[0].distributions = [4, 10];
    // formData.release_ids[1].distributions = [4];
    //formData.release_ids[2].distributions = [4]
    //formData.release_ids[3].distributions = [4]
    //console.log(formData);return false

    for (let i = 0; i < oldPlatformDetails.length; i++) {
      oldPlatformDetailsIds.push(oldPlatformDetails[i]["release_id"]);
      if (
        !newPlatformDetailsIds.includes(oldPlatformDetails[i]["release_id"])
      ) {
        if (
          !finalPlatform.is_deleted.includes(
            oldPlatformDetails[i]["release_id"]
          )
        )
          finalPlatform.is_deleted.push(oldPlatformDetails[i]["release_id"]);
      }
      if (!oldRegionDetailsIds.includes(oldPlatformDetails[i]["locality_id"])) {
        oldRegionDetailsIds.push(oldPlatformDetails[i]["locality_id"]);
      }

      if (
        oldformatDistributions[oldPlatformDetails[i]["release_id"]] ===
        undefined
      ) {
        oldformatDistributions[oldPlatformDetails[i]["release_id"]] = [];
      }
      if (
        !oldformatDistributions[oldPlatformDetails[i]["release_id"]].includes(
          oldPlatformDetails[i]["distribution_id"]
        )
      ) {
        oldformatDistributions[oldPlatformDetails[i]["release_id"]].push(
          oldPlatformDetails[i]["distribution_id"]
        );
      }
    }
    for (let i = 0; i < oldRegionDetailsIds.length; i++) {
      if (!newRegionDetailsIds.includes(oldRegionDetailsIds[i])) {
        if (!finalRegion.is_deleted.includes(oldRegionDetailsIds[i]))
          finalRegion.is_deleted.push(oldRegionDetailsIds[i]);
      }
    }
    for (let n = 0; n < newPlatformDetailsIds.length; n++) {
      let is_added = 0;
      for (let o = 0; o < oldPlatformDetails.length; o++) {
        if (oldPlatformDetailsIds.includes(newPlatformDetailsIds[n])) {
          break;
        } else if (!oldPlatformDetailsIds.includes(newPlatformDetailsIds[n])) {
          is_added = newPlatformDetailsIds[n];
        }
      }
      if (is_added > 0) {
        if (!finalPlatform.is_added.includes(is_added))
          finalPlatform.is_added.push(is_added);
      }
    }
    for (let n = 0; n < newRegionDetailsIds.length; n++) {
      let is_added = 0;
      for (let o = 0; o < oldRegionDetailsIds.length; o++) {
        if (oldRegionDetailsIds.includes(newRegionDetailsIds[n])) {
          break;
        } else if (!oldRegionDetailsIds.includes(newRegionDetailsIds[n])) {
          is_added = newRegionDetailsIds[n];
        }
      }
      if (is_added > 0) {
        finalRegion.is_added.push(is_added);
      }
    }
    console.log("ARR", prevdata.edition_id, formData.edition_ids[0]);
    if (prevdata.edition_id !== formData.edition_ids[0]) {
      finalEdition = true;
      newEditionId = formData.edition_ids[0];
    }
    if (prevdata.title !== formData.title) {
      finalTitle = true;
    }
    if (prevdata.title_type !== formData.title_type) {
      finalTitleType = true;
    }
    for (let i = 0; i < formData.release_ids.length; i++) {
      if (newformatDistributions[formData.release_ids[i]["id"]] === undefined) {
        newformatDistributions[formData.release_ids[i]["id"]] = [];
      }
      if (
        !newformatDistributions[formData.release_ids[i]["id"]].includes(
          formData.release_ids[i]["distributions"]
        )
      ) {
        let temp = [];
        for (
          let c = 0;
          c < formData.release_ids[i]["distributions"].length;
          c++
        ) {
          temp.push(formData.release_ids[i]["distributions"][c]);
        }

        newformatDistributions[formData.release_ids[i]["id"]] = temp;
      }
    }

    for (let nkey in newformatDistributions) {
      finalDistributions[nkey] = { is_added: [], is_deleted: [] };
      let add_ids = [];
      for (let ni = 0; ni < newformatDistributions[nkey].length; ni++) {
        if (oldformatDistributions[nkey] !== undefined) {
          if (
            oldformatDistributions[nkey].includes(
              newformatDistributions[nkey][ni]
            )
          ) {
            continue;
          } else if (
            !oldformatDistributions[nkey].includes(
              newformatDistributions[nkey][ni]
            )
          ) {
            add_ids.push(newformatDistributions[nkey][ni]);
          }
        } else {
          add_ids.push(newformatDistributions[nkey][ni]);
        }
      }
      if (add_ids.length > 0) {
        finalDistributions[nkey].is_added = add_ids;
      }
    }
    for (let nkey in oldformatDistributions) {
      if (newformatDistributions[nkey] !== undefined) {
        let deleted_ids = [];
        for (let ni = 0; ni < oldformatDistributions[nkey].length; ni++) {
          if (
            newformatDistributions[nkey].includes(
              oldformatDistributions[nkey][ni]
            )
          ) {
            continue;
          } else if (
            !newformatDistributions[nkey].includes(
              oldformatDistributions[nkey][ni]
            )
          ) {
            deleted_ids.push(oldformatDistributions[nkey][ni]);
          }
        }
        if (deleted_ids.length > 0) {
          finalDistributions[nkey].is_deleted = deleted_ids;
        }
      }
    }
    //const duprevdata = prevdata
    console.log("Previous Data", prevdata.details);
    const finalPutRequest = [];
    const requestObject = {};
    // requestObject.details = prevdata.details;
    const deleteObjectList = {};
    requestObject.title = formData.title;
    requestObject.title_type = formData.title_type;
    requestObject.details = [];
    if (
      finalEdition === true ||
      (finalTitle === true && finalEdition === true)
    ) {
      requestObject.edition_id = newEditionId;
      requestObject.edition_id_update = true;
      for (let i = 0; i < prevdata.details.length; i++) {
        let temp = prevdata.details[i];
        if (finalEdition === true) temp.details_delete = true;
        requestObject.details.push(temp);
      }
      for (let p = 0; p < formData.platforms.length; p++) {
        for (let l = 0; l < formData.locality_ids.length; l++) {
          for (let r = 0; r < formData.release_ids.length; r++) {
            if (formData.platforms[p] === formData.release_ids[r].id) {
              for (
                let d = 0;
                d < formData.release_ids[r].distributions.length;
                d++
              ) {
                let temp = {};
                temp.details_added = true;
                temp.release_id = formData.platforms[p];
                temp.locality_id = formData.locality_ids[l];
                temp.distribution_id = formData.release_ids[r].distributions[d];
                requestObject.details.push(temp);
              }
            }
          }
        }
      }
    } else {
      requestObject.edition_id = prevdata.edition_id;
      if (finalTitle === true) {
        for (let i = 0; i < prevdata.details.length; i++) {
          let temp = prevdata.details[i];
          requestObject.details.push(temp);
        }
      }

      /*****making delete object**************/
      for (let pd = 0; pd < finalPlatform.is_deleted.length; pd++) {
        for (let i = 0; i < prevdata.details.length; i++) {
          if (prevdata.details[i].release_id === finalPlatform.is_deleted[pd]) {
            prevdata.details[i].details_delete = true;
            requestObject.details.push(prevdata.details[i]);
          }
        }
      }
      for (let pr = 0; pr < finalRegion.is_deleted.length; pr++) {
        for (let i = 0; i < prevdata.details.length; i++) {
          if (prevdata.details[i].locality_id === finalRegion.is_deleted[pr]) {
            prevdata.details[i].details_delete = true;
            requestObject.details.push(prevdata.details[i]);
          }
        }
      }
      for (let pkey in finalDistributions) {
        for (let d = 0; d < finalDistributions[pkey].is_deleted.length; d++) {
          for (let prev = 0; prev < prevdata.details.length; prev++) {
            if (
              finalDistributions[pkey].is_deleted[d] ===
                prevdata.details[prev].distribution_id &&
              parseInt(pkey) === prevdata.details[prev].release_id
            ) {
              prevdata.details[prev].details_delete = true;
              requestObject.details.push(prevdata.details[prev]);
            }
          }
        }
      }
      /*****End - making delete object******/

      /*****making add object***************/

      for (let r = 0; r < finalRegion.is_added.length; r++) {
        for (let fp = 0; fp < formData.platforms.length; fp++) {
          for (let fd = 0; fd < formData.release_ids.length; fd++) {
            // console.log(formData.release_ids[fd].id,formData.platforms[fp])
            if (formData.release_ids[fd].id === formData.platforms[fp]) {
              for (
                let d = 0;
                d < formData.release_ids[fd].distributions.length;
                d++
              ) {
                //console.log(formData.release_ids[fd].distributions[d])
                let temp = {};
                temp.details_added = true;
                temp.locality_id = finalRegion.is_added[r];
                temp.release_id = formData.platforms[fp];
                temp.distribution_id =
                  formData.release_ids[fd].distributions[d];
                requestObject.details.push(temp);
              }
            }
          }
        }
      }
      //console.log(finalPlatform,formData.locality_ids,formData.release_ids)
      for (let p = 0; p < finalPlatform.is_added.length; p++) {
        for (let fr = 0; fr < formData.locality_ids.length; fr++) {
          for (let fd = 0; fd < formData.release_ids.length; fd++) {
            if (formData.release_ids[fd].id === finalPlatform.is_added[p]) {
              //console.log(finalPlatform.is_added[p])
              for (
                let d = 0;
                d < formData.release_ids[fd].distributions.length;
                d++
              ) {
                let temp = {};
                temp.details_added = true;
                temp.locality_id = formData.locality_ids[fr];
                temp.release_id = finalPlatform.is_added[p];
                temp.distribution_id =
                  formData.release_ids[fd].distributions[d];
                requestObject.details.push(temp);
              }
            }
          }
        }
      }
      for (let dkey in finalDistributions) {
        for (let d = 0; d < finalDistributions[dkey].is_added.length; d++) {
          for (let fr = 0; fr < formData.locality_ids.length; fr++) {
            let temp = {};
            temp.details_added = true;
            temp.locality_id = formData.locality_ids[fr];
            temp.release_id = parseInt(dkey);
            temp.distribution_id = finalDistributions[dkey].is_added[d];
            requestObject.details.push(temp);
          }
        }
      }
      /*****End - making add object********/
    }
    // console.log(requestObject.details);
    console.log(
      "Final Details - distr",
      finalDistributions,
      "Final Details - Edition",
      finalEdition,
      "Final Details - Platform",
      finalPlatform,
      "Final Details - Region",
      finalRegion
    );

    let filteredRequestObj = { ...requestObject };
    filteredRequestObj.details = [];
    let titleIds = [];
    requestObject.details.map((el) => {
      if (el.title_id === undefined) {
        filteredRequestObj.details.push(el);
      } else if (!titleIds.includes(el.title_id)) {
        filteredRequestObj.details.push(el);
        // titleIds[`$el.titleIds}`] = true;
        titleIds.push(el.title_id);
        // } else if (!titleIds[el.title_id] && el.title_id === undefined) {
        // filteredRequestObj.details.push(el);
      }
      // console.log("TITLE IDS", el.title_id);
    });
    console.log("Filtered Object", titleIds, filteredRequestObj);

    // finalPutRequest.push(requestObject);
    finalPutRequest.push(filteredRequestObj);
    console.log("Data to set", finalPutRequest);
    setForm(finalPutRequest);
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      {console.log("GAMEID", gameid)}
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      {/* {console.log("jaibalayya", selRegions)} */}
      <div>
        <MultiSelect
          options={regions}
          value={selRegions}
          onChange={setselRegions}
          labelledBy="Select Regions"
        />
        {/* <Select options={regions} value={selRegions} onChange={setselRegions} /> */}
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <Select
          // isDisabled={true}
          options={editions}
          value={selEdition}
          onChange={setselEdition}
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Title</div>
      <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
        <Select
          options={titleTypes}
          value={selTitleType}
          onChange={setselTitleType}
        />
        <input
          value={formData.title}
          id="title"
          onChange={(e) => handleFormData(e)}
          type="text"
          placeholder="Title name"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Distributions</div>

      <div>
        {selplatforms.map((elem) => {
          let opt = getdistribution
            .filter((el) => el.release_id === elem.release_id)[0]
            ?.distributions_details.map((elt) => {
              return {
                label: elt.distribution_name,
                value: elt.distribution_name,
                distribution_id: elt.distribution_id,
              };
            });
          return (
            <Memoizdcomp
              elem={elem}
              opt={opt}
              selplatforms={selplatforms}
              prevdata={prevdata}
              distplatObj={distplatObj}
              setdistplatObj={handleCallback}
              tempVar={tempVar}
              func={Pull}
            />
          );
        })}
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log("form2525", formData);
            editTitleHandler();
            // fetch("http://3.7.220.43/api/games/alternate-titles/", {
            //   method: "POST",
            //   body: JSON.stringify(formData),
            //   headers: {
            //     "Access-Control-Allow-Origin": "*",
            //     "Content-Type": "application/json",
            //   },
            // })
            //   .then((res) => res.json())
            //   .then(() => {
            //     handleSave();
            //   });
            // try {
            //   fetch("http://3.7.220.43/api/games/alternate-titles/", {
            //     method: "POST",
            //     body: JSON.stringify(formData),
            //     headers: {
            //       "Access-Control-Allow-Origin": "*",
            //       "Content-Type": "application/json",
            //     },
            //   })
            //     .then((res) => res.json())
            //     .then(() => {
            //       handleSave();
            //     });
            // } catch (error) {
            //   alert(error[0]);
            //   console.log("error is:", error[0]);
            // }
          }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default EditTitle;
