import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function EditDeveloper({ refresh, dataToPasson }) {
  // console.log("dataToPassonDEVid:", dataToPasson.platforms_detail_list[0].id);
  var arr = [];
  let { platforms } = useContext(AuthContext);

  // let temp = dataToPasson.platforms.map((elem) => {
  //   let data = platforms.map((el) => {
  //     if (Number(el.id) === Number(elem)) {
  //       arr.push(el);
  //     }
  //   });
  //   return data;
  // });
  // console.log("platformss", dataToPasson.platforms);
  let [selplatforms, setselPlatforms] = useState(arr);
  let [selCompanies, setselCompanies] = useState([]);

  // console.log("arr", arr);

  let init = {
    name: "",
    type: dataToPasson.type,
  };

  console.log("datatopasson", dataToPasson);

  let companies = [
    {
      id: 1,
      name: "Division48",
      label: "Division48",
      value: "Division48",
    },
    {
      id: 2,
      name: "CD Projekt S.A.",
      label: "CD Projekt S.A.",
      value: "CD Projekt S.A.",
    },
    {
      id: 3,
      name: "Blueberry Interactive",
      label: "Blueberry Interactive",
      value: "Blueberry Interactive",
    },
    {
      id: 4,
      name: "Company 13",
      label: "Company 13",
      value: "Company 13",
    },
  ];
  let [formData, setFormData] = useState(init);

  let handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.id]:
        e.target.id === "platforms" ? [Number(e.target.value)] : e.target.value,
    });
  };

  let handleSave = () => {
    setFormData(init);
    setselPlatforms([]);
    setselCompanies([]);
    refresh();
  };

  useEffect(() => {
    // let selected = selplatforms.map((el) => el.id);
    // console.log("Selected companies are:", selCompanies);
    setFormData({ ...formData, name: selCompanies.label });

    return null;
  }, [selCompanies]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      {console.log("Selected companies are:", selCompanies.label)}
      {console.log("Formdata is:", formData)}
      {/* <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div> */}
      <div style={{ textAlign: "center" }}>Dev Type</div>
      <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
        <select
          id="type"
          value={formData.type}
          onChange={(e) => handleFormData(e)}
        >
          <option selected={true}>Select Dev type</option>
          <option>Developer</option>
          <option>Additional Developer</option>
          <option>Ported/Enhanced Developer</option>
          <option>Re-Release Developer</option>
        </select>
        <Select
          options={companies}
          value={selCompanies}
          onChange={setselCompanies}
        />
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log(
              "formDataDEVELOPER:",
              dataToPasson.platforms_detail_list[0].id,
              formData
            );
            //3.7.220.43/api/games/developer-company-role/24/
            fetch(
              `http://3.7.220.43/api/games/developer-company-role/${dataToPasson.platforms_detail_list[0].id}/`,
              {
                method: "PATCH",
                body: JSON.stringify(formData),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(() => {
                handleSave();
              });
          }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default EditDeveloper;
