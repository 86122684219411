import React, { useState, useEffect, useContext } from "react";
import classes from "../MainContent.module.css";
import style from "./Description.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import comment from "../../../../icons/comment.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";

function Description() {
  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);

  let [descriptionData, setDescriptionData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);

  let [isLoading, setIsloading] = useState(true);

  let [page, setPage] = useState(1);
  let [dSP, setDSP] = useState(true);
  let [dSN, setDSN] = useState(false);

  let [dataToPasson, setDataToPasson] = useState([]);
  let handleAdd = () => {
    setDataToPasson(descriptionData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  let handleEdit = (data) => {
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
  };

  let handleDelete = (id) => {
    // console.log(id);
    fetch(
      `http://3.7.220.43/api/games/description-and-keyfeature/${id}/
    `,
      {
        method: "DELETE",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then(() => getData());
  };

  let getData = () => {
    // console.log("REQUET IS", platforms.map((elt) => elt["id"]).join(","));
    fetch(
      `http://3.7.220.43/api/games/descriptionandkeyfeature-by-game/20645/?&platform_id=${
        platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
      }&distribution_id=${
        distributions !== []
          ? distributions.map((elt) => elt["id"]).join(",")
          : ""
      }&locality_id=${
        regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
      }&edition_id=${
        editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("GET request made", data);
        setDescriptionData(data);
        setIsloading(false);
      });
  };

  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, distributions, editions]);

  useEffect(() => {
    // console.log(
    //   "descriptionData.length",
    //   descriptionData.length,
    //   "IN PAGE,",
    //   page,
    //   "data is,",
    //   descriptionData?.slice((page - 1) * 5, page * 5)
    // );
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= descriptionData.length / 5 && setDSN(true);
    page < descriptionData.length / 5 && setDSN(false);
  }, [page, descriptionData]);

  return (
    <div id="description" className={classes.section}>
      {/* {console.log(
        "Filter Context is,",
        platforms,
        regions,
        editions,
        distributions
      )} */}
      <div>
        {!showAge && (
          <h2
            onClick={() => setShowage(!showAge)}
            className={classes.buttonTextCompressed}
          >
            {"Description & Key Features"}
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2
                className={classes.buttonTextExpanded}
                onClick={() => setShowage(!showAge)}
              >
                {"Description & Key Features"}
              </h2>
              <h2
                style={{
                  margin: "10px",
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <img
                  src={comment}
                  alt="commentBtn"
                  onClick={() => setShowComments(!showComments)}
                />
              </h2>
              {showComments && (
                <Comments
                  setShowComments={setShowComments}
                  showComments={showComments}
                />
              )}
            </div>

            {descriptionData !== [] && (
              <div className={style.gridContainer}>
                <div>
                  <div className={style.SectionMid}>
                    <div className={style.sectionHeading}>Summary</div>
                    <div>
                      <button
                        className={style.AddBtn}
                        onClick={() => handleAdd()}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className={style.tableContainer}>
                    {/* <inputs placeholder></input>
                    <inputs placeholder></input>
                    <inputs placeholder></input>
                    <inputs placeholder></input>
                    <inputs placeholder></input> */}
                    <table>
                      <thead>
                        <tr>
                          <th className={style.tableHeading}>Region</th>
                          <th className={style.tableHeading}>Edition</th>
                          <th className={style.tableHeading}>Description</th>
                          <th className={style.tableHeading}>Key Features</th>
                          <th className={style.tableHeading}>Platform</th>
                          <th className={style.tableHeading}>Distribution</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {console.log("DAta is:,", descriptionData)} */}
                        {descriptionData
                          .slice((page - 1) * 5, page * 5)
                          .map((el) => {
                            return (
                              <tr key={nanoid()}>
                                <td>
                                  {el["regions_detail_list"]
                                    .map(
                                      (elt) =>
                                        `${elt["name"]} ${elt["iso_code"]}`
                                    )
                                    .join(", ")}
                                </td>
                                <td>
                                  {el["editions_detail_list"].length !== 0
                                    ? el["editions_detail_list"]
                                        .map((elt) => elt["name"])
                                        .join(", ")
                                    : "Primary"}
                                </td>
                                <td>
                                  <div
                                    style={{
                                      height: "100px",
                                      border: "1px solid",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {el["description"]
                                      ? el["description"]
                                      : "-"}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  {/* {console.log(el["key_features"])} */}
                                  <div
                                    style={{
                                      height: "100px",
                                      border: "1px solid",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {el["key_features"] !== ""
                                      ? el["key_features"]
                                      : // .split("\n\n")
                                        // .map((elt) => {
                                        //   return (
                                        //     <p style={{ margin: "0px" }}>
                                        //       {elt}
                                        //     </p>
                                        //   );
                                        // })
                                        "-"}
                                  </div>
                                </td>
                                <td>
                                  {el["platforms_detail_list"].length !== 0
                                    ? el["platforms_detail_list"]
                                        .map((elt) => elt["name"])
                                        .join(", ")
                                    : "-"}
                                </td>
                                <td>{el["distribution_detail"]["name"]}</td>
                                <td className={style.SectionBtns}>
                                  <img
                                    onClick={() => handleEdit(el)}
                                    src={edit}
                                    alt="Edit div"
                                  />
                                </td>
                                <td className={style.SectionBtns}>
                                  <img
                                    onClick={() => {
                                      handleDelete(el.reference_id);
                                    }}
                                    src={d_btn}
                                    alt="Delete div"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <button disabled={dSP} onClick={() => setPage(page - 1)}>
                    Prev
                  </button>
                  <button>{page}</button>
                  <button disabled={dSN} onClick={() => setPage(page + 1)}>
                    Next
                  </button>
                </div>
                <div>
                  {showEdit && (
                    <Edit setShowEdit={setShowEdit} dataPassed={dataToPasson} />
                  )}
                  {showAdd && (
                    <Add
                      setShowAdd={setShowAdd}
                      dataPassed={dataToPasson}
                      description={true}
                    />
                  )}
                </div>
                <button disabled={dSP} onClick={() => setPage(page - 1)}>
                  Prev
                </button>
                <button>{page}</button>
                <button disabled={dSN} onClick={() => setPage(page + 1)}>
                  Next
                </button>
              </div>
            )}

            <CollapseBtn setState={setShowage} state={showAge} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Description;
