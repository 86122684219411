import React, { useState, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function AddLink({ refresh }) {
  let init = {
    locality_id: "",
    platforms: [],
    edition_id: "",
    game_id: 20645,
    distribution_id: 10,
    links: "",
  };

  let [formData, setFormData] = useState(init);
  let { platforms, regions, editions } = useContext(AuthContext);

  let [selplatforms, setselPlatforms] = useState(formData.platforms);
  let [selRegions, setselRegions] = useState(formData.locality_id);
  let [selEdition, setselEdition] = useState(formData.edition_id);
  let [selLinkType, setselLinkType] = useState(formData.links);

  let [linkObj, setLinkObj] = useState({ link_type: "", url: "" });

  let linkType = [
    {
      id: 1,
      name: "Official",
      label: "Official",
      value: "Official",
    },
    {
      id: 2,
      name: "Twitter",
      label: "Twitter",
      value: "Twitter",
    },
  ];

  let handleFormData = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  let handleLinkObj = (e) => {
    setLinkObj({ ...linkObj, [e.target.id]: e.target.value });
  };

  let handleSave = () => {
    setFormData(init);
    setselPlatforms([]);
    setselEdition([]);
    setselRegions([]);
    setselLinkType([]);
    refresh();
  };
  useEffect(() => {
    console.log("Somethings being changed");
    setFormData({
      ...formData,
      platforms: selplatforms.map((el) => el.id),
      // region: selRegions.map((el) => el.id),
      locality_id: selRegions.id,
      edition_id: selEdition,
    });
  }, [selplatforms, selEdition, selRegions]);

  useEffect(() => {
    setLinkObj({ ...linkObj, link_type: selLinkType.name });
  }, [selLinkType]);

  useEffect(() => {
    setFormData({ ...formData, links: [linkObj] });
  }, [linkObj]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      <div>
        {/* <MultiSelect
          options={regions}
          value={selRegions}
          onChange={setselRegions}
          labelledBy="Select Regions"
        /> */}
        <Select options={regions} value={selRegions} onChange={setselRegions} />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <Select
          // isDisabled={true}
          options={editions}
          value={selEdition}
          onChange={setselEdition}
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Link</div>
      <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
        <Select
          options={linkType}
          value={selLinkType}
          onChange={setselLinkType}
        />
        <input
          id="url"
          onChange={handleLinkObj}
          type="text"
          value={linkObj.url}
          placeholder="Link here"
        />
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log(formData);
            try {
              fetch("http://3.7.220.43/api/games/links/", {
                method: "POST",
                body: JSON.stringify(formData),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              })
                .then((res) => res.json())
                .then(({ type }) => {
                  console.log("Response is:,", type);
                  handleSave();
                });
            } catch (error) {
              alert(error[0]);
              console.log("error is:", error[0]);
            }
          }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default AddLink;
