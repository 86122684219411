import React, { useState, useRef, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";
function AddCover() {
  const form = useRef(null);
  let { platforms, editions, ageRatings, regions, descriptors } =
    useContext(AuthContext);
  let [selplatforms, setselPlatforms] = useState([]);

  let formData = new FormData();
  formData.append("game", 4);
  // temp.append("key", "value");
  // console.log("Temp Fomrdata", temp);

  let handleFormData = (e) => {
    formData.delete(e.target.id);
    if (e.target.id === "image") {
      formData.append(e.target.id, e.target.files[0]);
    } else {
      formData.append(e.target.id, e.target.value);
    }
    console.log("New Data", ...formData);
  };
  useEffect(() => {
    console.log("Before", ...formData);
    formData.delete("platform");
    formData.append("platform", selplatforms.id);
    console.log("After", ...formData);
  }, [selplatforms]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        {/* <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        /> */}
        <Select
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      <div>
        <select
          style={{ width: "100%" }}
          id="region"
          value={formData.regions}
          onChange={(e) => {
            console.log("Value for", e.target.id, "is:", e.target.value);
            handleFormData(e);
          }}
        >
          <option>Select Region</option>
          {regions.map((el) => (
            <option key={Math.random()} value={el.id}>
              {el.label}
            </option>
          ))}
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <select
          style={{ width: "100%" }}
          id="edition"
          value={formData.edition}
          onChange={(e) => {
            console.log("Value for", e.target.id, "is:", e.target.value);
            handleFormData(e);
          }}
        >
          <option>Select Edition</option>
          {editions.map((el) => (
            <option key={Math.random()} value={el.id}>
              {el.label}
            </option>
          ))}
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Distribution</div>
      <div>
        <select
          id="distribution"
          onChange={handleFormData}
          name="Distribution"
          style={{ width: "100%" }}
        >
          <option value={"1"}>Option 1</option>
          <option value={"2"}>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Cover</div>
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "45% 1% 54%" }}>
          <input
            id="image"
            onChange={handleFormData}
            type="file"
            placeholder="Select Image"
            accept="image/*"
          />
          <div></div>
          <input type="url" placeholder="Enter url" />
        </div>
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log(...formData);
            fetch("http://3.7.220.43/api/games/coverimage/", {
              method: "POST",
              body: formData,
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
            })
              .then((res) => res.json())
              .then((data) => console.log(data));
          }}
        >
          SAVE
        </button>
      </div>
      {/* <form ref={form} onSubmit={submit}>
        <select
          className="testSelect"
          name="Distribution"
          style={{ width: "100%" }}
        >
          <option value={"optd1"}>Option 1</option>
          <option value={"optd2"}>Option 2</option>
        </select>
        <select className="testSelect" name="Edition" style={{ width: "100%" }}>
          <option value={"opt1"}>Option 1</option>
          <option value={"opt2"}>Option 2</option>
        </select>
        <select className="testSelect" name="Region" style={{ width: "100%" }}>
          <option value={"optr1"}>Option 1</option>
          <option value={"optr2"}>Option 2</option>
        </select>
        <input type="submit" name="Sign Up" />
      </form> */}
    </div>
  );
}

export default AddCover;
