import React, { useState, useContext, useEffect } from "react";
import classes from "../MainContent.module.css";
import style from "./Developer.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import close from "../../../../icons/close.svg";
import comment from "../../../../icons/comment.svg";
import filter_btn from "../../../../icons/filter.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";
import { useParams } from "react-router";

function Developer() {
  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);

  let temp = useParams();
  let Game_id = temp.id;

  let [developerData, setDeveloperData] = useState([]);
  // let [filterDevData, setFilteredData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);

  let [page, setPage] = useState(1);
  let [dSP, setDSP] = useState(true);
  let [dSN, setDSN] = useState(false);

  let [dataToPasson, setDataToPasson] = useState([]);

  //-------------filter states here---------------------//

  const [filterType, setfilterType] = useState("");
  const [filterName, setfilterName] = useState("");
  const [filterPlatform, setfilterPlatform] = useState("");
  const [showfilterType, setShowfilterType] = useState(false);
  const [showfilterName, setShowfilterName] = useState(false);
  const [showfilterPlatform, setShowfilterPlatform] = useState(false);

  let handleAdd = () => {
    setDataToPasson(developerData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  // let handleDelete = (id) => {
  //   // console.log(id);
  //   fetch(`http://3.7.220.43/api/games/developer/${id}/`, {
  //     method: "DELETE",
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   }).then(() => getData());
  // };

  let handleDelete = (primary_release, el) => {
    let dlte_msg = prompt("Are You Sure You Want To Delete Data");
    if (dlte_msg === "delete") {
      if (primary_release === true) {
        fetch(
          `http://3.7.220.43/v1/api/delete-developer/${el.platform_details[0].company_role_id}`,
          {
            method: "DELETE",
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        ).then(() => getData());
      } else {
        let ID_to_delete;
        ID_to_delete = el.platform_details
          .map((el) => el.company_role_id)
          .join("_");

        fetch(`http://3.7.220.43/v1/api/delete-developer/${ID_to_delete}`, {
          method: "DELETE",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }).then(() => getData());
      }
    }
  };

  let handlePatch = (id) => {
    // console.log(id);
    fetch(`http://3.7.220.43/api/games/developer/${id}/`, {
      method: "PATCH",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "TEST Dev TYPE 1212",
      }),
    }).then(() => getData());
  };

  let handleEdit = (data) => {
    handlePatch(data.id);
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
  };

  // let getData = () => {
  //   fetch(
  //     `http://3.7.220.43/api/games/developers-by-game/20443/?&platform_id=${
  //       platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
  //     }&distribution_id=${
  //       distributions !== []
  //         ? distributions.map((elt) => elt["id"]).join(",")
  //         : ""
  //     }&locality_id=${
  //       regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
  //     }&edition_id=${
  //       editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
  //     }`
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log("GET request made");
  //       setDeveloperData(data);
  //     });
  // };

  let getData = () => {
    // console.log(
    //   "ASDFGH",
    //   platforms.map((el) => el.release_id)
    // );
    fetch(`http://3.7.220.43/v1/api/developer/${Game_id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log("GET request made", data);
        setDeveloperData(data);
      });
  };

  // useEffect(() => {
  //   // setFilteredData(developerData.filter((el)=>)) FILTER LOGIC
  // }, [developerData])

  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, distributions, editions]);

  useEffect(() => {
    // console.log("developerData.length", developerData.length);
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= developerData.length / 5 && setDSN(true);
    page < developerData.length / 5 && setDSN(false);
  }, [page, developerData]);

  return (
    <div id="developers" className={classes.section}>
      <div>
        {!showAge && (
          <h2
            onClick={() => setShowage(!showAge)}
            className={classes.buttonTextCompressed}
          >
            Developer
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <h2
                  className={classes.buttonTextExpanded}
                  onClick={() => setShowage(!showAge)}
                >
                  Developer
                </h2>
                {/* <span
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignContent: "center",
                  }}
                > */}
                <img
                  style={{
                    margin: "10px",
                    // display: "flex",
                    // alignItems: "center",
                  }}
                  src={comment}
                  height="22px"
                  alt="commentBtn"
                  onClick={() => setShowComments(!showComments)}
                />
                {/* </span> */}
                {showComments && (
                  <Comments
                    setShowComments={setShowComments}
                    showComments={showComments}
                  />
                )}
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={close}
                  alt="collapse_logo"
                  onClick={() => setShowage(!showAge)}
                />
              </span>
            </div>

            <div className={style.gridContainer}>
              <div>
                <div className={style.SectionMid}>
                  <h4 className={style.summary}>Summary</h4>
                  {/* <div className={style.sectionHeading}>
                    <h3>Summary</h3>
                  </div> */}
                  {/* <div>
                    <button
                      className={style.AddBtn}
                      onClick={() => handleAdd()}
                    >
                      Add
                    </button>
                  </div> */}
                </div>
                <div className={style.tableContainer}>
                  <table>
                    <thead>
                      <tr>
                        {/* <th className={style.tableHeading}>
                          <h5> Type</h5>
                        </th> */}
                        <th
                          style={{ maxWidth: "55px" }}
                          className={style.tableHeading}
                          // style={{ maxWidth: "55px" }}
                        >
                          {!showfilterType ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                onClick={() =>
                                  setShowfilterType(!showfilterType)
                                }
                                className={style.filterImg}
                                src={filter_btn}
                                alt="filter_logo"
                              />
                              <h5 style={{ display: "inline-block" }}>Type</h5>
                            </div>
                          ) : (
                            <div>
                              <input
                                // className={style.input}

                                placeholder="Type"
                                onChange={(e) => setfilterType(e.target.value)}
                              />
                              <span
                                onClick={() =>
                                  setShowfilterType(!showfilterType)
                                }
                              >
                                &nbsp;X
                              </span>
                            </div>
                          )}
                        </th>
                        <th
                          style={{ maxWidth: "55px" }}
                          className={style.tableHeading}
                        >
                          {!showfilterName ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                onClick={() =>
                                  setShowfilterName(!showfilterName)
                                }
                                className={style.filterImg}
                                src={filter_btn}
                                alt="filter_logo"
                              />
                              <h5 style={{ display: "inline-block" }}>Name</h5>
                            </div>
                          ) : (
                            <div>
                              <input
                                // className={style.input}

                                placeholder="Type"
                                onChange={(e) => setfilterName(e.target.value)}
                              />
                              <span
                                onClick={() =>
                                  setShowfilterName(!showfilterName)
                                }
                              >
                                &nbsp;X
                              </span>
                            </div>
                          )}
                        </th>
                        <th
                          style={{ maxWidth: "55px" }}
                          className={style.tableHeading}
                        >
                          {!showfilterPlatform ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                onClick={() =>
                                  setShowfilterPlatform(!showfilterPlatform)
                                }
                                className={style.filterImg}
                                src={filter_btn}
                                alt="filter_logo"
                              />
                              <h5 style={{ display: "inline-block" }}>
                                Platform
                              </h5>
                            </div>
                          ) : (
                            <div>
                              <input
                                // className={style.input}

                                placeholder="Type"
                                onChange={(e) =>
                                  setfilterPlatform(e.target.value)
                                }
                              />
                              <span
                                onClick={() =>
                                  setShowfilterPlatform(!showfilterPlatform)
                                }
                              >
                                &nbsp;X
                              </span>
                            </div>
                          )}
                        </th>
                        <th
                          className={style.tableHeading}
                          // style={{ backgroundColor: "red" }}
                        >
                          {/* <div> */}
                          <button
                            className={style.AddBtn}
                            onClick={() => handleAdd()}
                          >
                            Add
                          </button>
                          {/* </div> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {developerData
                        .slice((page - 1) * 5, page * 5)
                        .map((el) => {
                          return (
                            <tr key={nanoid()}>
                              <td>{el["developer_type"]}</td>
                              <td>{el["company_name"]}</td>
                              <td>
                                {el["platform_details"]
                                  .map((elt) => elt["platform_name"])
                                  ////--------------------filter function is used to avoid null values at platform details when All platforms are available-----------------/////
                                  .filter((elem) => elem !== undefined)
                                  .join(", ")}
                              </td>
                              <td className={style.SectionBtns}>
                                <img
                                  onClick={() => handleEdit(el)}
                                  src={edit}
                                  alt="Edit div"
                                />
                                <img
                                  onClick={() => {
                                    handleDelete(el.primary_release, el);
                                  }}
                                  src={d_btn}
                                  alt="Delete div"
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <button disabled={dSP} onClick={() => setPage(page - 1)}>
                  Prev
                </button>
                <button>{page}</button>
                <button disabled={dSN} onClick={() => setPage(page + 1)}>
                  Next
                </button>
              </div>
              <div></div>
              <div>
                {showEdit && (
                  <Edit setShowEdit={setShowEdit} dataPassed={dataToPasson} />
                )}
                {showAdd && (
                  <Add
                    setShowAdd={setShowAdd}
                    dataPassed={dataToPasson}
                    developer={true}
                    getData={getData}
                  />
                )}
              </div>
            </div>

            {/* <CollapseBtn setState={setShowage} state={showAge} /> */}
          </div>
        )}
      </div>
    </div>
  );
}
export default Developer;
