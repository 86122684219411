import React from "react";

function GameAuditData() {
  return (
    <div>
      <h3>GAME AUDIT DATA</h3>
      <span>Stored at</span>
      <br />
      <span>Last Modified</span>
      <br />
      <span>Last Modified by</span>
      <div style={{ textAlign: "center" }}>
        <a
          href="https://test.gameopedia.com/staff/games/20645/audits"
          target="_blank"
          rel="noopener noreferrer"
        >
          See more
        </a>
      </div>
    </div>
  );
}

export default GameAuditData;
