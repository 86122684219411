import React, { useState, useEffect, useContext } from "react";
import styles from "./filters.module.css";
import fltr_btn from "../../../icons/filter.svg";
import { MultiSelect } from "react-multi-select-component";
import { FilterContext } from "../../../context/FilterContext";
import { AuthContext } from "../../../context/DataContext";

function Filters() {
  let { platforms, editions, regions, distributions } = useContext(AuthContext);
  let { setPlatforms, setRegions, setEditions, setDistributions } =
    useContext(FilterContext);
  let [plat, setPlat] = useState([]);
  let [reg, setReg] = useState([]);
  let [edi, setEdi] = useState([]);
  let [dist, setDist] = useState([]);

  let [selPlat, setselPlat] = useState([]);
  let [selReg, setselReg] = useState([]);
  let [selEdi, setselEdi] = useState([]);
  let [selDist, setselDist] = useState([]);

  useEffect(() => {
    setPlatforms(selPlat);
  }, [selPlat]);

  useEffect(() => {
    setRegions(selReg);
  }, [selReg]);

  useEffect(() => {
    setEditions(selEdi);
  }, [selEdi]);

  useEffect(() => {
    setDistributions(selDist);
  }, [selDist]);

  let getData = () => {
    fetch("http://3.7.220.43/api/games/games/20443/")
      .then((res) => res.json())
      .then(
        ({
          platforms_detail_list,
          regions_detail_list,
          distribution_detail_list,
          editions,
        }) => {
          // console.log(
          //   "data is",
          //   platforms_detail_list,
          //   regions_detail_list,
          //   editions,
          //   distribution_detail_list
          // );
          setDist(distribution_detail_list);
          setEdi(edi);
          setPlat(platforms_detail_list);
          setReg(regions_detail_list);
        }
      );
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <div className={styles.filter_cntnr}>
      {/* {console.log("FILTERS", distributions, editions, regions, platforms)} */}
      <div className={styles.fltr_child}>
        <div className={styles.fltr_elems}>
          <img className={styles.filterImg} src={fltr_btn} alt="filter"></img>

          <MultiSelect
            options={platforms}
            value={selPlat}
            onChange={setselPlat}
            labelledBy="Select Platforms"
          />

          <MultiSelect
            options={regions}
            value={selReg}
            onChange={setselReg}
            labelledBy="Select Regions"
          />
          <MultiSelect
            options={editions}
            value={selEdi}
            onChange={setselEdi}
            labelledBy="Select Editions"
          />
          <MultiSelect
            options={distributions}
            value={selDist}
            onChange={setselDist}
            labelledBy="Select Distributions"
          />
          {/* <select>
            <option>Dummy</option>
           {edi.map((el) => {
              {
                console.log("El", el);
              }
              return <option>{el}</option>;
            })}
          </select>
          <select>
            {dist.map((el) => (
              <option>{el.name}</option>
            ))}
          </select> */}
          {/* <button className={styles.filter}>
            <span>Filter</span>
          </button> */}
          <button className={styles.Clear}>
            <small>Clear</small>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
