import React, { useState, useEffect, useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
var arr = [];
function Distributionreusable({
  elem,
  opt,
  selplatforms,
  prevdata,
  distplatObj,
  setdistplatObj,
  tempVar,
  func,
}) {
  let { flg, setFlg } = useContext(AuthContext);
  // console.log("PREV", prevdata);
  let rawdist = prevdata?.details.filter(
    (elt) => elem.platform_name === elt.platform_name
  );
  let { distContext, setDistContext } = useContext(AuthContext);
  let [distplat, setdistplat] = useState({});
  let [dup, setdup] = useState({});
  let removedup_dist = {};

  {
    rawdist?.map(function (obj) {
      if (obj.distribution_name in removedup_dist) {
        return false;
      } else {
        removedup_dist[obj.distribution_name] = obj.distribution_id;
        // console.log("jaibalayya", removedup_dist);
      }
    });
  }
  const [option, setOption] = useState(
    Object.keys(removedup_dist).map((el) => {
      return {
        distribution_name: el,
        distribution_id: removedup_dist[el],
        label: el,
        value: el,
      };
    })
  );
  // console.log("op", elem);

  // arr.push(distplat);
  // console.log("arrr", arr);

  // useEffect(() => {
  //   arr.push(distplat);
  //   console.log("arr", arr);
  //   setdup(arr);
  //   console.log("dup", dup);
  // }, [distplat]);

  // setdistplatObj(arr);
  // console.log(setdistplatObj);

  // useEffect(() => {
  // let tempDist = () => {
  //   if (distplatObj.length === 0) {
  //     console.log("INITIAL SETUP", distplat);
  //     if (distplat !== {}) {
  //       console.log("FOUnd");
  //       return [distplat];
  //     } else {
  //       console.log("!NOT FOUnd");
  //     }
  //   } else {
  //     return distplatObj.map((elt) => {
  //       console.log("elt.id", elt, elem.release_id);
  //       if (elt.id === elem.release_id) {
  //         console.log("primary fresh", elt);
  //         return {
  //           id: elt.id,
  //           distributions: option.map((el) => el.distribution_id),
  //         };
  //       } else {
  //         console.log("secondary", elt);
  //         return elt;
  //       }
  //     });
  //   }
  // };
  // tempDist();
  // Object.keys(distplat).length !== 0 &&
  // setdistplatObj(() => {
  //   if (distplatObj.length === 0) {
  //     // console.log("INITIAL SETUP", Object.keys({}).length, distplat);
  //     // if (Object.keys(distplat).length !== 0) {
  //     //   console.log("FOUnd");
  //     return [distplat];
  //     // } else {
  //     //   console.log("!NOT FOUnd");
  //     // }
  //   } else {
  //     return distplatObj.map((elt) => {
  //       console.log("elt.id", elt, elem.release_id);
  //       if (elt.id === elem.release_id) {
  //         console.log("primary fresh", elt);
  //         return {
  //           id: elt.id,
  //           distributions: option.map((el) => el.distribution_id),
  //         };
  //       } else {
  //         console.log("secondary", elt);
  //         return elt;
  //       }
  //     });
  //   }
  // });
  // Object.keys(distplat).length !== 0 &&
  // console.log("DISTRPLAT", distContext);
  // setdistplatObj([...distplatObj, distplat]);
  // setDistContext([...distContext, distplat]);
  // setdistplatObj(tempVar);
  // }, [distplat]);
  //BEFORRE - {}
  // useEffect(() => {
  //   setdistplat({
  //     id: elem.release_id,
  //     distributions: option.map((el) => el.distribution_id),
  //   });
  // }, [option]);
  // IT IS FULL {xffchvsdbfk;sdhvsbd}

  useEffect(() => {
    setFlg(!flg);
  }, [option]);

  func(option, elem.release_id);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
      {console.log("DIST", distContext)}
      {/* {tempVar.push(distplat)} */}
      <p>{elem.label}</p>
      {selplatforms !== [] && (
        <MultiSelect
          options={opt || { label: "dummy", value: "dummy" }}
          value={option}
          onChange={setOption}
          style={{ width: "200px" }}
        />
      )}
    </div>
  );
}

export default Distributionreusable;

export const Memoizdcomp = React.memo(Distributionreusable);
