import { useState, createContext } from "react";

export const FilterContext = createContext();

const FilterState = ({ children }) => {
  let [plat, setPlat] = useState([]);
  let [reg, setReg] = useState([]);
  let [edi, setEdi] = useState([]);
  let [dist, setDist] = useState([]);

  return (
    <FilterContext.Provider
      value={{
        platforms: plat,
        regions: reg,
        editions: edi,
        distributions: dist,
        setPlatforms: setPlat,
        setRegions: setReg,
        setDistributions: setDist,
        setEditions: setEdi,
      }}
    >
      {/* {console.log("Filter Context Loaded!")} */}
      {children}
    </FilterContext.Provider>
  );
};
export default FilterState;
