import React from "react";
import Titles from "./titles/Titles";
import Links from "./links/Links";
import Developer from "./developer/Developer";
import ReleaseDatePublishers from "./releaseDate_Publishers/ReleaseDatePublishers";
import Description from "./descripiton_KeyFeatures/Description";
import PackageContentType from "./packageContentType/PackageContentType";
import AgeRatings from "./ageRatings/AgeRatings";
import StoreLinkSKU from "./storeLink_SKU_BC_MSRP/StoreLinkSKU";
import CoverImages from "./covers_Images/CoverImages";
import Screenshots from "./screenshots/Screenshots";
import Editions from "./editions/Editions";
import styles from "./MainContent.module.css";

function MainContent() {
  return (
    <div className={styles.mainContainer}>
      <Developer />
      <Titles />
      <Links />
      <ReleaseDatePublishers />
      <Description />
      <PackageContentType />
      <AgeRatings />
      <StoreLinkSKU />
      <CoverImages />
      <Screenshots />
      <Editions />
    </div>
  );
}

export default MainContent;
