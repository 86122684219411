import { nanoid } from "nanoid";
import React, { useState, useEffect, useRef, useContext } from "react";
import Select from "react-select";
import { AuthContext } from "../../../../context/DataContext";
import flag from "../../../../icons/flag.svg";
import styles from "./GameDetails.module.css";
import { useParams } from "react-router";

function GameDetails() {
  let { id: gameid } = useParams();
  let [game, setGame] = useState([]);
  let { gameTypes } = useContext(AuthContext);
  let nameRef = useRef(game.title);
  let gametypeRef = useRef(game.game_type);
  let cb1Ref = useRef(game.working_title);
  let cb2Ref = useRef(game.unofficial_title);

  let [gamename, setGameName] = useState(game.title);

  let getData = () => {
    fetch(`http://3.7.220.43/v1/game/${gameid}`)
      .then((res) => res.json())
      .then((data) => {
        setGame(data[0]);
        console.log("Obtained data is ", data);
      });
  };

  useEffect(() => {
    setGameName(game.title);
    // setCheckbox1(game.working_title);
    // setCheckbox2(game.official_title);
  }, [game]);

  const handleSubmit = (e) => {
    const formData = {
      title: gamename,
      game_type: gametypeRef.current.value,
      working_title: cb1Ref.current.checked ? 1 : 0,
      unofficial_title: cb2Ref.current.checked ? 1 : 0,
    };
    console.log(formData);
    fetch(`http://3.7.220.43/v1/update-game/${gameid}`, {
      method: "PATCH",
      body: JSON.stringify(formData),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((body) => body.json())
      .then((res) => console.log(res))
      .then(() => window.location.reload());
  };

  useEffect(() => {
    getData();
  }, []);

  return game === {} ? null : (
    <div className={styles.gameDetails_container}>
      <div>
        <div className={styles.gameDetails_title}>
          Game Details
          <img className={styles.gameDetails_flag} src={flag} alt="flag icon" />
        </div>
        <div className={styles.detailForm}>
          <div className={styles.formGrid}>
            <label htmlFor="title">Title</label>
            <input
              ref={nameRef}
              type="text"
              name="title"
              onChange={(e) => setGameName(e.target.value)}
              defaultValue={game.title}
            />
          </div>
          <div className={styles.formGrid}>
            <label htmlFor="gametype">Game Type</label>
            {console.log("GAMETYPES", gameTypes)}
            <select
              ref={gametypeRef}
              // defaultValue={game.game_type}
              id="gametype"
            >
              <option value="disabled" disabled>
                Choose Game type
              </option>
              {gameTypes !== "" &&
                gameTypes.map((el) => (
                  <option
                    key={nanoid()}
                    selected={el.value === game.game_type}
                    value={el.value}
                  >
                    {el.label}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.formGrid}>
            <label htmlFor="gameTitle_status"></label>
            <div>
              <div>
                <input
                  ref={cb1Ref}
                  type="checkbox"
                  defaultChecked={game.working_title}
                  name="officialTitle"
                  id="officialTitle"
                />
                <label htmlFor="officialTitle">Working Title?</label>
              </div>
              <div>
                <input
                  ref={cb2Ref}
                  type="checkbox"
                  defaultChecked={game.unofficial_title}
                  name="unofficialTitle"
                  id="unofficialTitle"
                />
                <label htmlFor="unofficialTitle">Unofficial Title?</label>
              </div>
            </div>
          </div>
          <div className={styles.gameDetails_update}>
            <button onClick={handleSubmit}>Update</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameDetails;
