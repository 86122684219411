import React, { useState, useEffect, useContext } from "react";
import classes from "../MainContent.module.css";
import style from "./AgeRatings.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import comment from "../../../../icons/comment.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";

function AgeRatings() {
  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);

  let [displayFilter, setDisplay] = useState(true);

  let [ageData, setAgeData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);
  let [dataToPasson, setDataToPasson] = useState([]);

  let [page, setPage] = useState(1);
  let [dSP, setDSP] = useState(true);
  let [dSN, setDSN] = useState(false);

  let [loading, setLoading] = useState(true);

  // let data = useContext(AuthState);
  // console.log("Set Data is: ", data);

  //-------------------------filter states-------------------------------//

  const [filterRegion, setfilterRegion] = useState("");
  const [filterAgerating, setfilterAgerating] = useState("");
  const [filterDescriptor, setfilterDescriptor] = useState("");
  const [filterPlatform, setfilterPlatform] = useState("");

  let handleAdd = () => {
    // setDataToPasson(ageData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  let handleEdit = (data) => {
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
  };

  let handleDelete = (id) => {
    // console.log(id);
    fetch(`http://3.7.220.43/api/games/game_agerating/${id}/`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then(() => getData());
  };

  // let getData = () => {
  //   fetch(
  //     "http://3.7.220.43/api/games/gameagerating-by-game/20645-a-bird-story/"
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log("GET request made");
  //       setAgeData(data);
  //     });
  // };
  let getData = () => {
    setLoading(true);
    fetch(
      `http://3.7.220.43/api/games/gameagerating-by-game/20645/?&platform_id=${
        platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
      }&distribution_id=${
        distributions !== []
          ? distributions.map((elt) => elt["id"]).join(",")
          : ""
      }&locality_id=${
        regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
      }&edition_id=${
        editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("AGE RATINGs - GET request made", data);
        setLoading(false);
        setAgeData(data);
      });
  };

  useEffect(() => {
    return () => {
      setAgeData([]);
    };
  }, []);

  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, distributions, editions]);

  useEffect(() => {
    // console.log("ageData.length", ageData.length);
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= ageData.length / 5 && setDSN(true);
    page < ageData.length / 5 && setDSN(false);
  }, [page, ageData]);

  return (
    <div id="ageRatings" className={classes.section}>
      <div>
        {!showAge && (
          <h2
            onClick={() => {
              setLoading(!loading);
              setShowage(!showAge);
            }}
            className={classes.buttonTextCompressed}
          >
            Age Ratings
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2
                className={classes.buttonTextExpanded}
                onClick={() => {
                  setLoading(!loading);
                  setShowage(!showAge);
                }}
              >
                Age Ratings
              </h2>
              <span
                style={{
                  margin: "10px",
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <img
                  src={comment}
                  alt="commentBtn"
                  onClick={() => setShowComments(!showComments)}
                />
              </span>
              {showComments && (
                <Comments
                  setShowComments={setShowComments}
                  showComments={showComments}
                />
              )}
              <CollapseBtn setState={setShowage} state={showAge} />
            </div>

            <div className={style.gridContainer}>
              <div>
                <div className={style.SectionMid}>
                  <h4 className={style.summary}>Summary</h4>
                  {/* <div className={style.sectionHeading}>Summary</div> */}
                  <div>
                    <button
                      className={style.AddBtn}
                      onClick={() => handleAdd()}
                    >
                      ADD
                    </button>
                  </div>
                </div>
                <div className={style.tableContainer}>
                  <input
                    onChange={(e) => setfilterRegion(e.target.value)}
                    placeholder="Region"
                  ></input>
                  <input
                    onChange={(e) => setfilterAgerating(e.target.value)}
                    placeholder="Age Rating"
                  ></input>
                  <input
                    onChange={(e) => setfilterDescriptor(e.target.value)}
                    placeholder="Descriptor"
                  ></input>
                  <input
                    onChange={(e) => setfilterPlatform(e.target.value)}
                    placeholder="Platform"
                  ></input>
                  <table>
                    <thead>
                      <tr>
                        {/* {["Region", "Age Rating", "Descriptor", "Platform"].map(
                          (item, index) => (
                            <th key={index} className={style.tableHeading}>
                              {item}
                            </th>
                          )
                        )} */}
                        <th className={style.tableHeading}>Region</th>
                        <th className={style.tableHeading}>Age Rating</th>
                        <th className={style.tableHeading}>Descriptor</th>
                        <th className={style.tableHeading}>Platform</th>
                      </tr>
                    </thead>
                    <tbody>
                      {console.log("Age Data is: ", ageData)}
                      {ageData
                        .filter(
                          (item) =>
                            item["regions_detail_list"]
                              .map((elt) => `${elt.name}${elt.iso_code}`)
                              .join("")
                              .toLowerCase()
                              .includes(filterRegion) &&
                            item["name"]
                              .toLowerCase()
                              .includes(filterAgerating) &&
                            (item["description"] !== null
                              ? item["description"]
                                  .toLowerCase()
                                  .includes(filterDescriptor)
                              : "-") &&
                            (item.platforms_detail_list !== ""
                              ? item.platforms_detail_list
                                  .map((el) => el.name)
                                  .join("")
                                  .toLowerCase()
                                  .includes(filterPlatform)
                              : "")
                        )
                        .map((el) => {
                          return (
                            <tr key={nanoid()}>
                              <td>
                                {el["regions_detail_list"]
                                  .map((elt) => `${elt.name}${elt.iso_code}`)
                                  .join(", ")}
                              </td>
                              <td>{el["name"]}</td>
                              <td>{el["description"] || "-"}</td>
                              <td>
                                {el["platforms_detail_list"]
                                  .map((elt) => elt["name"])
                                  .join(", ") || "-"}
                              </td>
                              <td className={style.SectionBtns}>
                                <button onClick={() => handleEdit(el)}>
                                  <img src={edit} alt="Edit Button" />
                                </button>
                              </td>
                              <td className={style.SectionBtns}>
                                <button
                                  onClick={() => {
                                    handleDelete(el.id);
                                  }}
                                >
                                  <img src={d_btn} alt="Delete Button" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <button disabled={dSP} onClick={() => setPage(page - 1)}>
                  Prev
                </button>
                <button>{page}</button>
                <button disabled={dSN} onClick={() => setPage(page + 1)}>
                  Next
                </button>
              </div>
              <div>
                {showEdit && (
                  <Edit
                    setShowEdit={setShowEdit}
                    dataPassed={dataToPasson}
                    // getData={getData}
                    AgeRating={true}
                  />
                )}
                {showAdd && (
                  <Add
                    setShowAdd={setShowAdd}
                    dataPassed={dataToPasson}
                    age={true}
                    getData={getData}
                  />
                )}
              </div>
            </div>

            <CollapseBtn setState={setShowage} state={showAge} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AgeRatings;
