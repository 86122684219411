import React from "react";
import styles from "./navigate.module.css";

function Navigate() {
  return (
    <div className={styles.navigate_cntnr}>
      <div className={styles.navigate_child}>
        <select
          defaultValue={"DEFAULT"}
          onChange={(e) => {
            window.location.href = `#${e.target.value}`;
          }}
        >
          <option value="DEFAULT" disabled>
            {"Navigate to"}
          </option>
          <option value="platforms">{"Platforms"}</option>
          <option value="titles">{"Titles"}</option>
          <option value="links">{"Links"}</option>
          <option value="developers">{"Developers"}</option>
          <option value="releaseData">{"Release Data & Publishers"}</option>
          <option value="description">{"Description & Key Features"}</option>
          <option value="ageRatings">{"Age Ratings"}</option>
          <option value="packageContent">{"Package COntent Type"}</option>
          <option value="storeLinks">{"Store Link & SKUs"}</option>
          <option value="coverImages">{"Cover Images"}</option>
          <option value="footer">{"Footer"}</option>
        </select>
      </div>
    </div>
  );
}

export default Navigate;
