import React from "react";
import styles from "./WarningTable.module.css";
import { nanoid } from "nanoid";

function WarningTable() {
  let warningData = [
    {
      Category: "Warning",
      Source: "Windows",
      Description:
        "Error descrption comes here in one line not exceeding say, .. 15 words. Works?",
    },
    {
      Category: "Error",
      Source: "Windows",
      Description:
        "Error lorem ipsum dsfhbdzf hemanth kumar kona sdfjbs sdvbsdhf sdvbsh dfhlbvxildfh dsjfbsdj sdvbsdfv xj cbxcjhvxn jxfbhds fvxdfsvhk sdvbsjvbds vsj fbsdjfv sdbfv jhx cjbsfv jhsd vjhsd fv xcvn vsjdvbsjzh descrptionn comes here in one line not exceeding say, .. 15 words. Works?",
    },
    {
      Category: "Warning",
      Source: "Windows",
      Description:
        "Error descrption comes here in one line not exceeding say, .. 15 words. Works?",
    },
    {
      Category: "Warning",
      Source: "Windows",
      Description:
        "Error descrption comes here in one line not exceeding say, .. 15 words. Works?",
    },
    {
      Category: "Warning",
      Source: "Windows",
      Description:
        "Error descrption comes here in one line not exceeding say, .. 15 words. Works?",
    },
  ];

  return (
    <div className={styles.PubContainer}>
      <div className={styles.TableContainer}>
        <table>
          <thead className={styles.stickyHeader}>
            <tr>
              <th>
                <p>Category</p>
              </th>
              <th>
                <p>Source</p>
              </th>
              <th>
                <p>Description</p>
              </th>
              <th>
                <p>Verify</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {warningData.map((el) => {
              return (
                <tr key={nanoid()}>
                  <td
                    className={
                      el["Category"] === "Warning"
                        ? styles.Warning_text
                        : styles.Error_text
                    }
                  >
                    {el["Category"]}
                  </td>
                  <td>{el["Source"]}</td>
                  <td>{el["Description"]}</td>
                  {el["Category"] === "Warning" ? (
                    <td>
                      <input type="checkbox" name="verify" id="verify_btn" />
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WarningTable;
