import React, { useState, useEffect, useContext } from "react";
import classes from "../MainContent.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import comment from "../../../../icons/comment.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import style from "./coverImages.module.css";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";

function CoverImages() {
  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);

  let [coverData, setCoverData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);
  let [dataToPasson, setDataToPasson] = useState([]);

  let [page, setPage] = useState(1);
  let [dSP, setDSP] = useState(true);
  let [dSN, setDSN] = useState(false);

  let handleAdd = () => {
    setDataToPasson(coverData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  let handleEdit = (data) => {
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
  };
  let handleDelete = (id) => {
    // console.log(id);
    fetch(`http://3.7.220.43/api/games/coverimage/${id}/`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then(() => getData());
  };
  let getData = () => {
    // fetch("http://3.7.220.43/api/games/coverimages-by-game/20443/")
    fetch(
      `http://3.7.220.43/api/games/coverimages-by-game/20443/?&platform_id=${
        platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
      }&distribution_id=${
        distributions !== []
          ? distributions.map((elt) => elt["id"]).join(",")
          : ""
      }&locality_id=${
        regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
      }&edition_id=${
        editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("COVERS - GET request made", data);
        setCoverData(data);
      });
  };

  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, editions, distributions]);

  useEffect(() => {
    // console.log("coverData.length", coverData.length);
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= coverData.length / 5 && setDSN(true);
    page < coverData.length / 5 && setDSN(false);
  }, [page, coverData]);

  return (
    <div id="coverImages" className={classes.section}>
      <div>
        {!showAge && (
          <h2
            onClick={() => setShowage(!showAge)}
            className={classes.buttonTextCompressed}
          >
            Covers / Images
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2
                className={classes.buttonTextExpanded}
                onClick={() => setShowage(!showAge)}
              >
                Covers / Images
              </h2>
              <span
                style={{
                  margin: "10px",
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <img
                  src={comment}
                  alt="commentBtn"
                  onClick={() => setShowComments(!showComments)}
                />
              </span>
              {showComments && (
                <Comments
                  setShowComments={setShowComments}
                  showComments={showComments}
                />
              )}
            </div>

            <div className={style.gridContainer}>
              <div>
                <div className={style.SectionMid}>
                  <div className={style.sectionHeading}>Summary</div>
                  <div>
                    <button
                      className={style.AddBtn}
                      onClick={() => handleAdd()}
                    >
                      ADD
                    </button>
                  </div>
                </div>
                <div className={style.tableContainer}>
                  <table>
                    <thead>
                      <tr>
                        <th className={style.tableHeading}>Platform</th>
                        <th className={style.tableHeading}>Region</th>
                        <th className={style.tableHeading}>Edition</th>
                        <th className={style.tableHeading}>Distribution</th>
                        <th className={style.tableHeading}>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coverData.slice((page - 1) * 5, page * 5).map((el) => {
                        return (
                          <tr key={nanoid()}>
                            <td>{el["platform_detail"]["name"]}</td>
                            <td>{`${el["region_detail"]["name"]} ${el["region_detail"]["iso_code"]}`}</td>
                            <td>
                              {el["edition_detail"] === null
                                ? "Primary"
                                : el["edition_detail"]["name"]}
                            </td>
                            <td>{el["distribution_detail"]["name"]}</td>
                            <td>
                              <img
                                src={el["thumbnail"]}
                                alt="Cover"
                                height={"120px"}
                              />
                            </td>
                            <td className={style.SectionBtns}>
                              <img
                                onClick={() => handleEdit(el)}
                                src={edit}
                                alt="Edit div"
                              />
                            </td>
                            <td className={style.SectionBtns}>
                              <img
                                onClick={() => {
                                  handleDelete(el.reference_id);
                                }}
                                src={d_btn}
                                alt="Delete div"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <button disabled={dSP} onClick={() => setPage(page - 1)}>
                  Prev
                </button>
                <button>{page}</button>
                <button disabled={dSN} onClick={() => setPage(page + 1)}>
                  Next
                </button>
              </div>
              <div>
                {showEdit && (
                  <Edit setShowEdit={setShowEdit} dataPassed={dataToPasson} />
                )}
                {showAdd && (
                  <Add
                    setShowAdd={setShowAdd}
                    dataPassed={dataToPasson}
                    cover={true}
                    getData={getData}
                  />
                )}
              </div>
            </div>

            <CollapseBtn setState={setShowage} state={showAge} />
          </div>
        )}
      </div>
    </div>
  );
}

export default CoverImages;
