import React from "react";

function Editions() {
  return (
    <div>
      <a
        href="https://test.gameopedia.com/staff/games/20645-a-bird-story/editions"
        target="_blank"
        rel="noopener noreferrer"
      >
        Editions
      </a>
    </div>
  );
}

export default Editions;
