import React, { useState, useEffect } from "react";
import dbtn from "../../../icons/delete.svg";
import styles from "./Platform.module.css";
import { nanoid } from "nanoid";
import { useParams } from "react-router";

function Platform() {
  let { id: gameid } = useParams();
  const [platforms, setplatforms] = useState([]);

  let getData = () => {
    fetch(`http://3.7.220.43/v1/game/${gameid}/`)
      .then((res) => res.json())
      .then((data) => {
        console.log("DETAILS:", data);
        setplatforms(data[0]["platform_details"]);
      });
  };

  let deletePlatform = (id) => {
    fetch(`http://3.7.220.43/v1/delete-platform/${id}`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then(() => getData());
  };
  useEffect(() => {
    getData();
    // .catch((err) => console.log("Server Not started"));
  }, []);

  return (
    <div id="platforms" className={styles.pf_container}>
      <div className={styles.pf_titles}>
        <h2>Platforms</h2>
        <span>
          <a href="http://duckduckgo.com">Legacy Page</a>
        </span>
      </div>
      <div className={styles.pf_names_container}>
        {platforms.map((el) => {
          return (
            <button key={nanoid()} className={styles.pf_names}>
              {el["platform_name"]}
              <span className={styles.delete_btn}>
                <img
                  onClick={() => deletePlatform(el["release_id"])}
                  src={dbtn}
                  alt="delete"
                  width={"18px"}
                />
              </span>
            </button>
          );
        })}
        <button className={styles.add_pf_btn}>
          <a
            href="https://test.gameopedia.com/staff/games/20645-a-bird-story/releases/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Add platform
          </a>
        </button>
      </div>
    </div>
  );
}

export default Platform;
