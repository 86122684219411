import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function AddReleaseDate() {
  let { platforms, regions, editions } = useContext(AuthContext);
  let [selplatforms, setselPlatforms] = useState([]);
  let [selRegions, setselRegions] = useState([]);
  let [selEditions, setselEditions] = useState([]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      <div style={{ textAlign: "center" }}>Platforms</div>
      {console.log("Received Info", platforms, regions, editions)}
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      <div>
        <MultiSelect
          options={regions}
          value={selRegions}
          onChange={setselRegions}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <MultiSelect
          options={editions}
          value={selEditions}
          onChange={setselEditions}
          labelledBy="Select Platforms"
        />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Company</div>
      <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
        <Select
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
        />
        <input type="text" placeholder="Link here" />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Release Date</div>
      <div>
        <input type={"text"} placeholder="RD" />
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Distrbution</div>
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
          {selplatforms.map((el) => console.log(el))}
          <div>Platform 1</div>
          <div>
            <select>
              <option>D 1</option>
              <option>D 2</option>
            </select>
          </div>
          <div>Platform 2</div>
          <div>
            <select>
              <option>D 1</option>
              <option>D 2</option>
            </select>
          </div>
          <div>Platform 3</div>
          <div>
            <select>
              <option>D 1</option>
              <option>D 2</option>
            </select>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default AddReleaseDate;
