import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { AuthContext } from "../../../../../context/DataContext";
import Select from "react-select";

function AddDeveloper({ refresh }) {
  let { platforms, company } = useContext(AuthContext);
  let [selplatforms, setselPlatforms] = useState([]);
  let [selCompanies, setselCompanies] = useState([]);

  let init = {
    name: "",
    type: "",
    platforms: [],
    game: 4,
  };

  let companies = [
    {
      id: 1,
      name: "Freebird Games",
      label: "Freebird Games",
      value: "Freebird Games",
    },
    {
      id: 2,
      name: "Company 11",
      label: "Company 11",
      value: "Company 11",
    },
    {
      id: 3,
      name: "Company 12",
      label: "Company 12",
      value: "Company 12",
    },
    {
      id: 4,
      name: "Company 13",
      label: "Company 13",
      value: "Company 13",
    },
  ];
  let [formData, setFormData] = useState(init);

  let handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.id]:
        e.target.id === "platforms" ? [Number(e.target.value)] : e.target.value,
    });
  };

  let handleSave = () => {
    setFormData(init);
    setselPlatforms([]);
    setselCompanies([]);
    refresh();
  };

  useEffect(() => {
    let selected = selplatforms.map((el) => {
      return el.id;
    });
    // console.log("Selected companies are:", selCompanies);
    setFormData({ ...formData, platforms: selected, name: selCompanies.label });

    return null;
  }, [selplatforms, selCompanies]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "15% 75% 10%" }}>
      {console.log("Selected companies are:", selCompanies.label)}
      {console.log("Companies are:", company)}
      {console.log("Formdata is:", formData)}
      <div
        style={{
          paddingRight: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <h5 style={{ margin: 0, fontSize: "12px" }}>Platforms:</h5>
      </div>
      <div>
        <MultiSelect
          options={platforms}
          value={selplatforms}
          onChange={setselPlatforms}
          labelledBy="Select Platforms"
          debounceDuration="1000"
        />
      </div>
      <div></div>
      <div
        style={{
          paddingRight: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <h5 style={{ margin: 0, fontSize: "12px" }}>Dev Type:</h5>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
        <select
          id="type"
          value={formData.type}
          onChange={(e) => handleFormData(e)}
        >
          <option selected={true}>Select Dev type</option>
          <option>Developer</option>
          <option>Additional Developer</option>
          <option>Ported/Enhanced Developer</option>
          <option>Re-Release Developer</option>
        </select>
        <Select
          options={company}
          value={selCompanies}
          onChange={setselCompanies}
        />
      </div>
      <div></div>
      <div></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log(formData);
            fetch("http://3.7.220.43/api/games/developer/", {
              method: "POST",
              body: JSON.stringify(formData),
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            })
              .then((res) => res.json())
              .then(() => {
                handleSave();
              });
          }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default AddDeveloper;
