import React from "react";

function AddPackageContent() {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <select style={{ width: "100%" }} multiple>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      <div>
        <select style={{ width: "100%" }} multiple>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <select style={{ width: "100%" }}>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Package Content</div>
      <div>
        <select style={{ width: "100%" }} multiple>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Distribution</div>
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
          <div>Platform 1</div>
          <div>
            <select>
              <option>D 1</option>
              <option>D 2</option>
            </select>
          </div>
          <div>Platform 2</div>
          <div>
            <select>
              <option>D 1</option>
              <option>D 2</option>
            </select>
          </div>
          <div>Platform 3</div>
          <div>
            <select>
              <option>D 1</option>
              <option>D 2</option>
            </select>
          </div>
        </div>
      </div>
      <div></div>
      <div></div>
    </div>
  );
}

export default AddPackageContent;
