import React from "react";
import collapse_logo from "../../../../icons/collapse.png";
import close from "../../../../icons/close.svg";

function CollapseBtn({ collapseFn, setState, state, setLoading, loading }) {
  return (
    <div
      onClick={() => console.log("Triggered!", collapseFn)}
      style={{
        // display: "flex",
        // justifyContent: "flex-end",
        paddingRight: "0.75%",
        // paddingRight: "15px",
        // paddingBottom: "2.5px",
      }}
    >
      <img
        onClick={() => {
          setState(!state);
          setLoading(!loading);
        }}
        src={collapse_logo}
        alt="collapse_logo"
      ></img>
    </div>
  );
}

export default CollapseBtn;
