import React from "react";
import styles from "./search.module.css";
import gop_logo from "../../../icons/gop_logo.png";
import search from "../../../icons/search.svg";

function Search() {
  return (
    <div className={styles.search_cntnr}>
      <div className={styles.search_child}>
        <div className={styles.search_text}>
          <button>
            <span>Add New Game</span>
          </button>
        </div>
        <div className={styles.search_input}>
          <img src={search} alt="search_logo"></img>
          <input placeholder="search" />
        </div>
        <div className={styles.gop_logo}>
          <img src={gop_logo} alt="gop_logo" />
        </div>
      </div>
    </div>
  );
}

export default Search;
