import React, { useState, useEffect, useContext } from "react";
import classes from "../MainContent.module.css";
import style from "./ReleaseDatePublishers.module.css";
import edit from "../../../../icons/edit.svg";
import d_btn from "../../../../icons/delete.svg";
import comment from "../../../../icons/comment.svg";
import CollapseBtn from "../Miscellaneous/CollapseBtn";
import Edit from "../Miscellaneous/Edit";
import Add from "../Miscellaneous/Add";
import Comments from "../Miscellaneous/Comments";
import { nanoid } from "nanoid";
import { FilterContext } from "../../../../context/FilterContext";

function ReleaseDatePublishers() {
  let { platforms, regions, editions, distributions } =
    useContext(FilterContext);
  let [page, setPage] = useState(1);
  let [dSP, setDSP] = useState(true);
  let [dSN, setDSN] = useState(false);
  let [releaseDateData, setReleaseDateData] = useState([]);
  let [showAge, setShowage] = useState(false);
  let [showEdit, setShowEdit] = useState(false);
  let [showAdd, setShowAdd] = useState(false);
  let [showComments, setShowComments] = useState(false);
  let [dataToPasson, setDataToPasson] = useState([]);

  //-----------------filter states-------------//
  const [filterPlatform, setfilterPlatform] = useState("");
  const [filterRegion, setfilterRegion] = useState("");
  const [filterEdition, setfilterEdition] = useState("");
  const [filterReleasedate, setfilterReleasedate] = useState("");
  const [filtercompany, setfiltercompany] = useState("");
  const [filterDistribution, setfilterDistribution] = useState("");
  const [showfilterPlatform, setshowfilterPlatform] = useState("");
  const [showfilterRegion, setshowfilterRegion] = useState("");
  const [showfilterEdition, setshowfilterEdition] = useState("");
  const [showfilterReleasedate, setshowfilterReleasedate] = useState("");
  const [showfiltercompany, setshowfiltercompany] = useState("");
  const [showfilterDistribution, setshowfilterDistribution] = useState("");

  let handleAdd = () => {
    setDataToPasson(releaseDateData[0]);
    showEdit && setShowEdit(false);
    setShowAdd(true);
  };

  let handleEdit = (data) => {
    setDataToPasson(data);
    showAdd && setShowAdd(false);
    setShowEdit(true);
  };

  let handleDelete = (id) => {
    // console.log(id);
    fetch(`http://3.7.220.43/api/games/release_publication/${id}/`, {
      method: "DELETE",
      mode: "cors",
    }).then(() => getData());
  };

  let getData = () => {
    fetch(
      `http://3.7.220.43/api/games/releasepublication-by-game/20443/?&platform_id=${
        platforms !== [] ? platforms.map((elt) => elt["id"]).join(",") : ""
      }&distribution_id=${
        distributions !== []
          ? distributions.map((elt) => elt["id"]).join(",")
          : ""
      }&locality_id=${
        regions !== [] ? regions.map((elt) => elt["id"]).join(",") : ""
      }&edition_id=${
        editions !== [] ? editions.map((elt) => elt["id"]).join(",") : ""
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("GET request made");
        setReleaseDateData(data);
      });
  };

  useEffect(() => {
    showAge && getData();
  }, [showAge, platforms, regions, distributions, editions]);

  useEffect(() => {
    // console.log("releaseDateData.length", releaseDateData.length);
    page > 1 && setDSP(false);
    page === 1 && setDSP(true);
    page >= releaseDateData.length / 5 && setDSN(true);
    page < releaseDateData.length / 5 && setDSN(false);
  }, [page, releaseDateData]);

  return (
    <div id="releaseData" className={classes.section}>
      <div>
        {!showAge && (
          <h2
            onClick={() => setShowage(!showAge)}
            className={classes.buttonTextCompressed}
          >
            {"Release Date & Publishers"}
          </h2>
        )}
      </div>
      <div>
        {showAge && (
          <div className={style.sectioncontainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2
                className={classes.buttonTextExpanded}
                onClick={() => setShowage(!showAge)}
              >
                {"Release Date & Publishers"}
              </h2>
              <span style={{ marginLeft: "20px" }}>
                <img
                  src={comment}
                  alt="commentBtn"
                  onClick={() => setShowComments(!showComments)}
                />
              </span>
              {showComments && (
                <Comments
                  setShowComments={setShowComments}
                  showComments={showComments}
                />
              )}
            </div>
            <div className={style.gridContainer}>
              <div>
                <div>
                  <div className={style.SectionMid}>
                    <div className={style.sectionHeading}>Summary</div>
                    <div>
                      <button
                        className={style.AddBtn}
                        onClick={() => handleAdd()}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className={style.tableContainer}>
                    <input
                      placeholder="platform"
                      onChange={(e) => setfilterPlatform(e.target.value)}
                    ></input>
                    <input
                      placeholder="Region"
                      onChange={(e) => setfilterRegion(e.target.value)}
                    ></input>
                    <input
                      placeholder="Edition"
                      onChange={(e) => setfilterEdition(e.target.value)}
                    ></input>
                    <input
                      placeholder="ReleaseDate"
                      onChange={(e) => setfilterReleasedate(e.target.value)}
                    ></input>
                    <input
                      placeholder="Company"
                      onChange={(e) => setfiltercompany(e.target.value)}
                    ></input>
                    <input
                      placeholder="Distribution"
                      onChange={(e) => setfilterDistribution(e.target.value)}
                    ></input>
                    <table>
                      <thead>
                        <tr>
                          <th className={style.tableHeading}>Platform</th>
                          <th className={style.tableHeading}>Region</th>
                          <th className={style.tableHeading}>Edition</th>
                          <th className={style.tableHeading}>RD</th>
                          <th className={style.tableHeading}>Company</th>
                          <th className={style.tableHeading}>Distribution</th>
                        </tr>
                      </thead>
                      <tbody>
                        {releaseDateData
                          .filter(
                            (item) =>
                              (item["regions_detail_list"].length !== 0
                                ? item.regions_detail_list
                                    .map((el) => el.name)
                                    .join("")
                                    .toLowerCase()
                                    .includes(filterRegion)
                                : "".includes(filterRegion)) &&
                              (item["platforms_detail_list"].length !== 0
                                ? item.platforms_detail_list
                                    .map((el) => el.name)
                                    .join("")
                                    .toLowerCase()
                                    .includes(filterPlatform)
                                : "".includes(filterPlatform)) &&
                              (item["editions_detail_list"].length !== 0
                                ? item["editions_detail_list"]
                                    .map((elt) => elt["name"])
                                    .join("")
                                : "Primary"
                              )
                                .toLowerCase()
                                .includes(filterEdition)
                          )
                          .map((el) => {
                            return (
                              <tr key={nanoid()}>
                                <td>
                                  {el["platforms_detail_list"].length !== 0
                                    ? el["platforms_detail_list"]
                                        .map((elt) => elt["name"])
                                        .join(", ")
                                    : "-"}
                                </td>
                                <td>
                                  {el["regions_detail_list"].map(
                                    (elt) => elt["name"]
                                  )}
                                </td>
                                <td>
                                  {el["editions_detail_list"].length !== 0
                                    ? el["editions_detail_list"]
                                        .map((elt) => elt["name"])
                                        .join(", ")
                                    : "Primary"}
                                </td>
                                <td>
                                  {el["release_date_time"]
                                    ? el["release_date_time"]
                                    : "Not available"}
                                  {/* REMOVE IF NOT NEEDED */}
                                </td>
                                <td>{el["company"] ? el["company"] : "-"}</td>
                                <td>{el["distribution"]}</td>
                                <td className={style.SectionBtns}>
                                  <button onClick={() => handleEdit(el)}>
                                    <img src={edit} alt="Edit Button" />
                                  </button>
                                </td>
                                <td className={style.SectionBtns}>
                                  <button
                                    onClick={() => {
                                      handleDelete(el.id);
                                    }}
                                  >
                                    <img src={d_btn} alt="Delete Button" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <button disabled={dSP} onClick={() => setPage(page - 1)}>
                    Prev
                  </button>
                  <button>{page}</button>
                  <button disabled={dSN} onClick={() => setPage(page + 1)}>
                    Next
                  </button>
                </div>
              </div>
              <div>
                {showEdit && (
                  <Edit setShowEdit={setShowEdit} dataPassed={dataToPasson} />
                )}
                {showAdd && (
                  <Add
                    setShowAdd={setShowAdd}
                    dataPassed={dataToPasson}
                    release={true}
                    getData={getData}
                  />
                )}
              </div>
            </div>
            <CollapseBtn setState={setShowage} state={showAge} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReleaseDatePublishers;
