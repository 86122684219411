import { useState, createContext } from "react";
import authContext from "./authContext";
import { Req } from "./utils";
import { useEffect } from "react";
// import { useParams } from "react-router";
// import axios from "axios";
import { useParams } from "react-router";

export const AuthContext = createContext();

const AuthState = ({ children }) => {
  let gameid;
  let setGameid = (idfromURL) => {
    gameid = idfromURL;
  };
  const [sss, setsss] = useState([]);
  const [platform, setPlatform] = useState("");
  const [edition, setEdition] = useState([
    {
      edition_id: 0,
      edition_name: "Primary Edition",
      label: "Primary Edition",
      value: "Primary Edition",
    },
  ]);
  let [distContext, setDistContext] = useState([1, 2, 3]);
  let [flg, setFlg] = useState(true);

  const [regions, setRegions] = useState("");
  const [titles, setTitles] = useState("");
  const [distributions, setDistributions] = useState("");
  const [ageratings, setAgeratings] = useState("");
  const [gameDescriptors, setgameDescriptors] = useState("");
  const [companies, setCompanies] = useState("");
  const [packageContents, setPackageContents] = useState("");
  const [titleTypes, setTitleTypes] = useState("");
  const [linkTypes, setLinkTypes] = useState("");
  const [gameTypes, setGameTypes] = useState("");

  const getData = async () => {
    let gameType = await Req.get("/api/game_types");
    console.log("DONE GAME TYPES", gameid);
    setGameTypes(gameType.data.game_type);

    const { data } = await Req.get(`/game/${gameid}/`);
    console.log("DONE Platforms");
    setPlatform(
      data[0]?.platform_details.map((el) => {
        return {
          release_id: el["release_id"],
          platform_name: el["platform_name"],
          label: el["platform_name"],
          value: el["platform_name"],
        };
      })
    );
    console.log("DONE Editions");
    setEdition(
      [...edition, ...data[0].edition_details].map((el) => {
        return {
          edition_id: el["edition_id"],
          edition_name: el["edition_name"],
          label: el["edition_name"],
          value: el["edition_name"],
        };
      })
    );
    console.log(
      "yyy",
      data[0].locality_details.map((el) => {
        return {
          locality_code: el["locality_code"],
          locality_id: el["locality_id"],
          locality_name: el["locality_name"],
          label: `${el["locality_name"]} (${el["locality_code"]})`,
          value: `${el["locality_name"]} (${el["locality_code"]})`,
        };
      })
    );
    console.log("DONE Regins");
    setRegions(
      data[0].locality_details.map((el) => {
        return {
          locality_code: el["locality_code"],
          locality_id: el["locality_id"],
          locality_name: el["locality_name"],
          label: `${el["locality_name"]} (${el["locality_code"]})`,
          value: `${el["locality_name"]} (${el["locality_code"]})`,
        };
      })
    );
    console.log("DONE Dsitributions");
    setDistributions(
      data[0].distribution_details.map((el) => {
        return {
          distribution_id: el["distribution_id"],
          distribution_name: el["distribution_name"],
          label: el["distribution_name"],
          value: el["distribution_name"],
        };
      })
    );

    // const ed = await Req.get("/api/editiondetails/20443/");
    // let temp = ed.data.map((el) => el.edition_details).flat();
    // console.log("Edition data is", [...new Set(temp)]);
    let titleTyp = await Req.get("/api/title_types/");
    console.log("DONE Title Types");
    setTitleTypes(titleTyp.data.title_type);
    let linkType = await Req.get("/api/link_types/");
    console.log("DONE Link Types");
    setLinkTypes(linkType.data.link_type);
    // console.log("Links Data is", linkType.data);
    // setTitleTypes(titleTyp);
    // setPlatform([]);
    // setRegions([]);
    setAgeratings([]);
    setgameDescriptors([]);
    setCompanies([]);
    setPackageContents([]);
    // setEdition([...edition, ...ed.data]);
    // const dist = await ReqNew.get("/distributions/");
    // setDistributions(dist);
    // const age = await Req.get("/agerating/");
    // setAgeratings(age);
    // const desc = await Req.get("/game_descriptor/");
    // setgameDescriptors(desc);
    // const comp = await Req.get("/company/");
    // setCompanies(comp);
    // const cont = await ReqNew.get("/package-contents/");
    // setPackageContents(cont);
    // const genre = await Req.get("/genre/");
    // setGenres(genre);
    // const misc = await Req.get("/miscellaneous/");
    // setMiscellaneous(misc);
  };

  useEffect(() => {
    getData();
  }, [gameid]);

  return (
    <AuthContext.Provider
      value={{
        platforms: platform,
        gameTypes: gameTypes,
        editions: edition,
        regions: regions,
        distributions: distributions,
        titleTypes: titleTypes,
        flg,
        setFlg,
        setGameid,
        distContext: distContext,
        setDistContext: setDistContext,
        companies,
        linktypes: linkTypes,
        ageRatings: ageratings.data,
        descriptors: gameDescriptors.data,
        packageContents: packageContents.data,
        setstate: setsss,
        state: sss,
      }}
    >
      {console.log("sss", gameid)}
      {children}
    </AuthContext.Provider>
  );
};
export default AuthState;
