import React, { useState, useContext } from "react";
// import { AuthContext } from "../../../../../context/DataContext";

function EditDescription({ refresh, dataToPasson }) {
  console.log("dataToPasson:", dataToPasson);
  let [formData, setFormData] = useState({});
  // let { platforms, regions, editions, titles } = useContext(AuthContext);

  let [selTitleType, setselTitleType] = useState(
    formData !== {} ? formData.title_type : ""
  );

  let handleFormData = ({ target }) => {
    const { name, value } = target;
    console.log("name, value :", name, value);
    setFormData({ ...formData, [name]: value });
  };
  let handleSave = () => {
    setFormData({});

    setselTitleType([]);
    refresh();
  };

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
        <div>Description</div>
        <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}></div>
        <div></div>
        <div></div>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div>
            <div>Retailer SKU</div>
            <div>
              <input
                type="date"
                onChange={(e) => handleFormData(e)}
                name="release_date"
              />
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          style={{ padding: "5px" }}
          onClick={() => {
            console.log(
              "formDataDescription:",

              formData
            );
            fetch(
              `http://3.7.220.43/api/games/release-date-and-publisher/${dataToPasson.reference_id}/`,
              {
                method: "PATCH",
                body: JSON.stringify(formData),
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(() => {
                handleSave();
              });
          }}
        >
          SAVE
        </button>
      </div>
    </>
  );
}

export default EditDescription;
