import React from "react";

function AddDescription() {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "30% 60% 10%" }}>
      <div style={{ textAlign: "center" }}>Platforms</div>
      <div>
        <select style={{ width: "100%" }} multiple>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Region</div>
      <div>
        <select style={{ width: "100%" }} multiple>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Edition</div>
      <div>
        <select style={{ width: "100%" }}>
          <option>Option 1</option>
          <option>Option 2</option>
        </select>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Description</div>
      <div>
        <textarea
          rows="4"
          cols="20"
          style={{ width: "99%" }}
          placeholder="Description of the game here"
        ></textarea>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Key Feature</div>
      <div>
        <textarea
          style={{ width: "99%" }}
          rows="4"
          cols="10"
          placeholder="KF of the game here"
        ></textarea>
      </div>
      <div></div>
      <div style={{ textAlign: "center" }}>Distrbution</div>
      <div>
        <select>
          <option>D 1</option>
          <option>D 2</option>
        </select>
      </div>
      <div></div>
    </div>
  );
}

export default AddDescription;
