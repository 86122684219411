import React from "react";

function Screenshots() {
  return (
    <div>
      <a
        href="https://test.gameopedia.com/staff/games/20645-a-bird-story/releases/20645-all-platforms/screenshots"
        target="_blank"
        rel="noopener noreferrer"
      >
        Screenshots
      </a>
    </div>
  );
}

export default Screenshots;
