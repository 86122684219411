import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/Footer";
import Body from "../components/body/Body";
import { useParams } from "react-router";

function UIpage() {
  let temp = useParams();
  let game_id = temp.id;
  //   const { id } = useParams();

  console.log("game_id", game_id);

  return (
    <div>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default UIpage;
