import React, { useState, useEffect } from "react";
import Classifications from "./classifications/Classifications";
import GameDetails from "./gameDetails/GameDetails";
import WarningTable from "./warningTable/WarningTable";
import styles from "./PubsComponent.module.css";
import { useParams } from "react-router";

function PubsComponent() {
  let temp = useParams();
  let game_id = temp.id;

  let [game, setgame] = useState([]);
  // let [isLoading, setLoading] = useState(false);

  let getData = () => {
    // setLoading(() => {
    //   // console.log("setting state to be false", game);
    //   return true;
    // });
    // fetch("http://3.7.220.43/api/games/games/20645/")
    fetch(`http://3.7.220.43/v1/api/game/classifications/${game_id}`)
      .then((res) => res.json())
      .then((data) => {
        setgame(data);
        // setLoading(() => {
        //   // console.log("setting state to be true", game);
        //   return false;
        // });
      });
  };
  // let getData1 = () => {
  //   fetch("http://3.7.220.43/v1/game/20645")
  //     .then((res) => res.json())
  //     .then((data1) => {
  //       setgamedetail(data1);
  //     });
  // };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.pubsContainer}>
      <div className={styles.pubsHeader}>
        <h2>PUBS Errors or Warnings </h2>
        <button className={styles.refreshbtn}>
          <a href="https://google.com" target={"blank"}>
            Refresh
          </a>
        </button>
      </div>
      <div className={styles.pubsComponent}>
        <div style={{ height: "50%" }}>
          <WarningTable />
        </div>
        <div className={styles.pubs_right}>
          {/* <GameDetails game={game} /> */}
          <GameDetails />
          <Classifications game={game} />
        </div>
      </div>
    </div>
  );
}

export default PubsComponent;
