import React from "react";
import styles from "./Classifications.module.css";
import { nanoid } from "nanoid";

function Classifications({ game }) {
  let { Genre, Miscellaneous } = game;
  let data = {
    Genre: Genre,
    Miscellaneous: Miscellaneous,
  };

  return data["Genre"] === undefined ? (
    <div></div>
  ) : (
    <div>
      <h5 className={styles.titleName}>CLASSIFICATIONS</h5>
      <div className={styles.classifications_container}>
        {Object.keys(data).map((el) => {
          return (
            <div key={nanoid()} className={styles.classElt}>
              <h5 className={styles.title}>{el}</h5>
              <span>{data[el].join(", ")}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Classifications;
